import Form from 'components/form/Home'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import ApiClient from 'data/api/api_client'
import useErrorHandler from 'data/hooks/useErrorHandler'
import ModalNavControl from 'components/ModalNavControl'
import useAuth from 'data/hooks/User/useAuth'
import AuthSectionPanel, { AuthSectionPanelProps, AuthSectionPanelSsoText } from 'components/auth/AuthSectionPanel'
import CustomerForgottenPasswordModal from 'components/modals/CustomerForgottenPasswordModal'
import { useEffect, useState } from 'react'

type LoginSectionFormFields = {
  email: string | undefined
  password: string | undefined
  remember_me: boolean | undefined
}

interface LoginSectionProps extends Pick<AuthSectionPanelProps, 'buttonStyle'> {
  placeholderPanel?: JSX.Element
}

const LoginSection = ({
  placeholderPanel,
  ...props
}: LoginSectionProps) => {
  const handleError = useErrorHandler()

  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false)
  const [showPlaceholderPanel, setShowPlaceholderPanel] = useState(placeholderPanel !== undefined)

  const { login } = useAuth()
  const { register, handleSubmit, setError, formState: { errors }, reset } = useForm<LoginSectionFormFields>()

  const loginMutation = useMutation(
    (details: LoginSectionFormFields) => ApiClient.auth.login(
      details.email,
      details.password,
      details.remember_me,
    ),
    {
      onSuccess: (user) => {
        reset()
        login(user)
      },
      onError: (error) => {
        handleError(error, setError)
      },
    },
  )

  const onSubmit = (details: LoginSectionFormFields) => {
    loginMutation.mutate(details)
  }

  const [buttonProps, setButtonProps] = useState<AuthSectionPanelProps['button']>()

  useEffect(() => {
    setTimeout(() => {
      setButtonProps(showPlaceholderPanel
        ? {
          text: 'Sign In',
          type: 'button',
          onClick: () => setShowPlaceholderPanel(false),
        }
        : {
          text: 'Sign In',
          type: 'submit',
          isLoading: loginMutation.isLoading,
        })
    }, 0)
  }, [showPlaceholderPanel, loginMutation.isLoading])

  return (
    <AuthSectionPanel
      onSubmit={handleSubmit(onSubmit)}
      button={buttonProps}
      socialLogin={{
        show: true,
        text: AuthSectionPanelSsoText.Login,
      }}
      {...props}
    >
      {
        (showPlaceholderPanel && placeholderPanel !== undefined)
          ? placeholderPanel
          : <>
            <Form.Input
              variant="grey"
              placeholder="Email Address"
              error={errors?.email?.message}
              {...register('email')}
            />

            <Form.Field className="flex flex-col gap-10">
              <Form.Input
                variant="grey"
                placeholder="Password"
                type="password"
                error={errors?.password?.message}
                {...register('password')}
              />


              <div className="flex justify-between">
                <Form.Checkbox
                  className="leading-20"
                  style="customer"
                  error={errors?.remember_me?.message}
                  {...register('remember_me')}
                >
                  <span className="text-14 text-grey-800">Remember Me</span>
                </Form.Checkbox>

                <ModalNavControl.Button
                  text="Forgot Password"
                  className="ml-auto text-grey-500 leading-20 text-14 hover:underline"
                  onClick={() => setIsForgotPasswordModalOpen(true)}
                />
              </div>
            </Form.Field>
          </>
      }


      <CustomerForgottenPasswordModal
        isOpen={isForgotPasswordModalOpen}
        onClose={() => setIsForgotPasswordModalOpen(false)}
      />
    </AuthSectionPanel>
  )
}

export default LoginSection
