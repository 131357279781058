import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryGuestModal from './PrimaryGuestModal'
import PublicMobileModal from 'components/PublicMobileModal'
import Headers from 'components/home/common/typography/Headers'
import { RestSharedUserInvite, RestUser } from 'data/types/user'
import { RootState } from 'store'
import InviteMoreGuestsTrigger from 'components/modals/InviteMoreGuestsTrigger'
import GuestDisclaimer from './Disclaimer'
import PrimaryGuestDisclaimer from './PrimaryGuestDisclaimer'
import { joinBy } from '@ama-selections/ui'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import ConfirmPublicModal from 'components/modals/ConfirmPublicModal'
import { useMutation } from 'react-query'
import { UserTripServiceShareModalFormFields } from 'components/booking/trip-schedule/UserTripServiceShareModal'
import { setSharedUsersAndInvites } from 'store/booking-shared-users'
import { useNotifier } from 'react-headless-notifier'
import { useForm } from 'react-hook-form'
import ApiClient from 'data/api/api_client'
import Notification from 'components/Notification'
import { SharedUser } from './SharedUser'
import { RestBooking } from 'data/types/booking'

interface InvitedGuestModalProps {
  booking: RestBooking
  isOpen: boolean
  onClose: () => void
}

const InvitedGuestModal = ({
  booking,
  isOpen,
  onClose = () => { },
}: InvitedGuestModalProps) => {
  const [isPrimaryGuestModalOpen, setIsPrimaryGuestModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<RestUser | RestSharedUserInvite | null>(null)
  const [isExplanationClicked, setIsExplanationClicked] = useState(false)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  const dispatch = useDispatch()
  const { notify } = useNotifier()

  const { setValue, reset } = useForm<UserTripServiceShareModalFormFields>({
    defaultValues: {
      confirm_share: false,
    },
  })

  const { user, sharedUsers, sharedUserInvites } = useSelector((state: RootState) => ({
    user: state.auth.user,
    sharedUsers: state.bookingSharedUsers.sharedUsers,
    sharedUserInvites: state.bookingSharedUsers.sharedUserInvites,
  }))

  const { mutate } = useMutation(
    (details: UserTripServiceShareModalFormFields) => ApiClient.bookings.inviteUser(details, booking.id),
    {
      onSuccess: (response) => {
        dispatch(setSharedUsersAndInvites(response))
        notify(
          <Notification message="User invite sent" type="success" />,
        )
        reset()
        onClose()
      },
      onError: (error: any) => {
        if (error.response.status !== 422) {
          notify(
            <Notification message="There was an issue inviting this user, please try again" type="error" />,
          )
        }
      },
    },
  )

  const fullAccessGiven = useMemo(
    () => sharedUsers?.find((user) => user.has_full_access),
    [sharedUsers],
  )

  useEffect(() => {
    if (selectedUser && !fullAccessGiven) {
      setIsPrimaryGuestModalOpen(true)
    }
  }, [selectedUser])

  const handleNameClick = (user: RestUser | RestSharedUserInvite) => {
    setSelectedUser(user)
  }

  return (
    <PublicMobileModal
      isOpen={isOpen}
      onClose={() => {
        setIsExplanationClicked(false)
        onClose()
      }}
      className={{
        panel: 'flex flex-col px-24 pb-20 lg:pb-30 max-w-lg',
      }}
    >
      <Headers.H1 className="pt-16 font-serif font-bold text-center mb-25">Your Guests</Headers.H1>

      { fullAccessGiven
        ? undefined
        : (
          !isExplanationClicked
            ? (
              <GuestDisclaimer
                onClick={() => setIsExplanationClicked(true)}
                className="px-24 py-12"
              >
                <p>
                  Choose one of your guests the <strong>Primary Guest</strong><br />
                  and give them full access to act on your behalf.
                </p>
                <u>What does this mean?</u>
              </GuestDisclaimer>
            )
            : (
              <PrimaryGuestDisclaimer>
                <p>
                  Only one of your guests can be given full access.
                </p>
                <u>Select a Primary Guest by tapping on their name.</u>
              </PrimaryGuestDisclaimer>
            )
        )
      }

      <div className="flex flex-col gap-10 mb-48">
        {
          (sharedUsers ?? []).length === 0 && (sharedUserInvites ?? []).length === 0
          && <Paragraphs.MD className="my-20 text-center text-grey-750 leading-20 text-14">No Invitations</Paragraphs.MD>
        }

        { sharedUsers?.map((user) => (
          <SharedUser
            user={user}
            key={user.id}
            label={user.has_full_access ? 'Primary Guest' : 'Guest'}
            showTick={!user.has_full_access}
            onClick={fullAccessGiven ? undefined : (() => handleNameClick(user))}
          />
        ))}

        { sharedUserInvites?.map((user) => (
          <SharedUser
            user={user}
            key={user.id}
            label="Invited"
            onClick={() => {
              setSelectedUser(user)
              setConfirmModalOpen(true)
            }}
          />
        ))}
      </div>
      {
        (booking?.client?.id === user?.id || user?.type === 'admin' || booking?.shared_users?.find(u => u.id === user?.id))
        && <div className="flex justify-center">
          <InviteMoreGuestsTrigger booking={booking} />
        </div>
      }

      <PrimaryGuestModal
        user={selectedUser as RestUser}
        booking={booking}
        isOpen={isPrimaryGuestModalOpen && !selectedUser?.is_invite}
        onClose={() => {
          setIsPrimaryGuestModalOpen(false)
          setSelectedUser(null)
        }}
      />

      <ConfirmPublicModal
        isOpen={(confirmModalOpen && selectedUser?.is_invite) ?? false}
        onClose={() => setConfirmModalOpen(false)}
        title="Send confirmation message"
        message="Did you want to share your link to this user again?"
        action={() => {
          setValue('confirm_share', true)
          mutate({
            confirm_share: true,
            email: selectedUser!.email,
            name: joinBy([
              selectedUser?.first_name,
              (selectedUser as RestUser)?.last_name,
            ], ' '),
          })
        }}
      />

    </PublicMobileModal>
  )
}

export default InvitedGuestModal
