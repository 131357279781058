import React from 'react'
import PropTypes from 'prop-types'
import AMALogo from 'assets/images/logo-no-tagline.jpg'
import Image from 'components/Image'
import { getProfileImage } from 'data/helpers/users'
import classNames from 'classnames'

const ProfileImage = ({
  user,
  className,
  textClassName,
  variant = 'customer',
}) => {
  const defaultClassNames = {
    text: classNames(
      'relative block',
      {
        'text-13 leading-22': variant === 'customer' && !textClassName,
        'text-blue-1000 font-sans tracking-1/4 font-bold': variant === 'customer',
        'text-13 text-white': variant === 'admin',
      },
      textClassName,
    ),
  }

  return (
    <>
      {getProfileImage(user)
        ? (
          <img
            src={getProfileImage(user)}
            className={classNames('object-cover rounded-full', className)}
          />
        )
        : !user?.id
          ? (
            <div className={classNames('relative', className)}>
              <Image
                src={AMALogo}
                layout="fill"
                className={classNames('object-scale-down rounded-full bg-white', className)}
              />
            </div>
          )
          : (
            <div className={classNames(
              'bg-grey-200 rounded-full flex items-center justify-center',
              className,
            )}>
              <span className={defaultClassNames.text}>
                {(user?.first_name ?? '')?.charAt(0)}
              </span>
            </div>
          )
      }
    </>
  )
}

ProfileImage.propTypes = {
  user: PropTypes.object,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  variant: PropTypes.oneOf(['customer', 'admin']),
}

export default ProfileImage
