import PublicMobileModalDrawer from 'components/PublicMobileModalDrawer'
import { ProfileModalNavigation } from 'components/user/profile/ProfileModalNavigation'
import useAuth from 'data/hooks/User/useAuth'
import { ModalProps } from 'data/types/modal'

interface CustomerProfileModalProps extends ModalProps {}

const CustomerProfileModal = ({
  isOpen,
  onClose,
}: CustomerProfileModalProps) => {
  const { user } = useAuth()

  return (
    <PublicMobileModalDrawer
      isOpen={isOpen}
      onClose={onClose}
      position="right"
      hasFocusTrap
      title={`Hello, ${user?.first_name ?? ''}!`}
      className={{
        panel: 'flex flex-col overflow-y-auto pb-safe-offset-10',
      }}
    >
      <ProfileModalNavigation
        onClose={onClose}
      />
    </PublicMobileModalDrawer>
  )
}

export default CustomerProfileModal
