import PublicNavbar from 'components/PublicNavbar'
import PublicFooter from 'components/PublicFooter'
import HowCanWeHelpYouSection from 'components/sections/HowCanWeHelpYouSection'
import useBodyClasses from 'data/hooks/useBodyClasses'
import { useScreenWidth } from 'data/hooks/useScreenWidth'
import CenteredCustomerLoginModal from 'components/modals/getHelp/CenteredCustomerLoginModal'
import { setAuthModal } from 'store/auth'
import { useDispatch, useSelector } from 'react-redux'
import CustomerLoginModal from 'components/modals/CustomerLoginModal'
import CustomerRegisterModal from 'components/modals/CustomerRegisterModal'
import { RootState } from 'store'
import { ReactChild } from 'data/types/types'
import { NextSeo, NextSeoProps } from 'next-seo'
import Image, { StaticImageData } from 'components/Image'
import { useMobileApp } from 'data/hooks/MobileApp/useMobileApp'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import Headers from 'components/home/common/typography/Headers'
import MobilePublicNavbar, { MobilePublicNavbarProps } from 'components/mobile/MobilePublicNavbar'
import classNames from 'classnames'
import CustomerForgottenPasswordModal from 'components/modals/CustomerForgottenPasswordModal'
import dynamic from 'next/dynamic'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import ActionFooter, { ActionFooterProps } from './ActionFooter'
import { Fragment } from 'react'
import UserDashboardNavbar from './UserDashboard/Navbar'
const CloudflareStream = dynamic(() => import('components/CloudflareStream'), {
  ssr: false,
})

export type PublicLayoutProps = {
  variant?: PublicLayoutVariant
  children: ReactChild
  title?: string
  seo?: NextSeoProps
  featuredImage?: {
    image: string | StaticImageData
    useVideo?: boolean
    title?: string
    darken?: boolean
    alt?: string
    search?: {
      form?: UseFormReturn<any>
      element: ReactChild,
      showTitleOnMobile?: boolean
    }
    size: 'md' | 'lg'
  }
  descriptionBlock?: {
    title: string
    description: string
  }
  header?: {
    title: string
    description?: string
  }
  mobileNavBar?: MobilePublicNavbarProps
  temporaryNavbar?: ReactChild
  actionFooter?: ActionFooterProps
  className?: {
    footer?: string
  }
}

export enum PublicLayoutVariant {
  Public = 'public',
  Home = 'home',
}

const PublicLayout = ({
  variant = PublicLayoutVariant.Public,
  children,
  seo,
  featuredImage,
  header,
  mobileNavBar,
  temporaryNavbar,
  className,
  actionFooter,
}: PublicLayoutProps) => {
  const { isMobile: isMobileDisplay } = useScreenWidth(1024)
  const { isMobileApp } = useMobileApp()
  const dispatch = useDispatch()

  const { loginModalOpen, centeredModalOpen, registerModalOpen, forgotPasswordModalOpen } = useSelector((state: RootState) => ({
    loginModalOpen: state.auth.loginModalOpen,
    centeredModalOpen: state.auth.centeredLoginModalOpen,
    registerModalOpen: state.auth.registerModalOpen,
    forgotPasswordModalOpen: state.auth.forgotPasswordModalOpen,
  }))

  useBodyClasses('font-sans', 'overflow-x-hidden')

  const Tag = featuredImage?.search?.form ? FormProvider : Fragment

  return (
    <Tag {...featuredImage?.search?.form as any}>
      { isMobileDisplay && mobileNavBar
        ? (<MobilePublicNavbar {...mobileNavBar} variant={variant} />)
        : (variant === PublicLayoutVariant.Public
          ? <PublicNavbar />
          : null
        )
      }

      {/* TODO: Remove this option when the Property Navbar functionality is merged into the Mobile Public Navbar */}
      {temporaryNavbar}

      {seo && <NextSeo {...seo} />}

      {/* Display on web-sized browsers only */}
      {(!isMobileApp && !isMobileDisplay && featuredImage) && (
        <div className={classNames(
          'min-h-[450px] flex flex-col justify-center items-center w-full relative mb-50',
          {
            'lg:min-h-[659px]': featuredImage.size === 'md',
            'lg:min-h-[790px]': featuredImage.size === 'lg',
          },
        )}>
          <div className="z-20 flex flex-col items-center w-full max-w-7xl">
            {featuredImage.title && (
              <Headers.Image
                className={classNames('mx-20 relative max-w-[300px] sm:max-w-[993px] font-bold font-serif text-center text-white filter drop-shadow-title px-20', {
                  'mb-[88px]': featuredImage.search,
                })}>
                {featuredImage.title}
              </Headers.Image>
            )}

            {featuredImage.search?.element}
          </div>

          { featuredImage.useVideo
            ? <div className="absolute w-screen h-full overflow-hidden">
              <CloudflareStream video/>
            </div>
            : <Image
              src={featuredImage.image}
              alt={featuredImage.alt}
              className={classNames(
                'object-cover',
                {
                  'brightness-75': featuredImage.darken,
                },
              )}
              layout="fill"
            />
          }

        </div>
      )}

      {/* display the header, separate from the content, if the user is on a desktop-view */}
      {(!isMobileApp && !isMobileDisplay && header) && (
        variant === PublicLayoutVariant.Public && (
          <div className="flex container-public lg:px-40">
            <div className="z-10 flex flex-col w-full max-w-5xl gap-20 pb-40 mx-auto text-center bg-white pt-30 md:pt-40 px-[28px] md:px-60 lg:px-110 -mt-100 md:gap-25 overlay-shadow md:shadow-none rounded-4">
              <Headers.PageHeader className="text-grey-900">
                {header.title}
              </Headers.PageHeader>

              {
                header.description && (
                  <Paragraphs.PublicHeader className="text-grey-750 md:font-serif">
                    {header.description}
                  </Paragraphs.PublicHeader>
                )
              }
            </div>
          </div>
        )
      )}

      <div className={classNames(
        {
          '-mt-24 lg:mt-0 rounded-t-24 relative flex flex-col bg-white min-h-screen-safe': variant === PublicLayoutVariant.Public,
          'relative mt-0 lg:h-screen flex flex-col min-h-0 overflow-y-auto': variant === PublicLayoutVariant.Home,
          'h-[calc(100vh-60px)]': variant === PublicLayoutVariant.Home && !mobileNavBar?.images,
        },
      )}>
        {
          (variant === PublicLayoutVariant.Home && !isMobileDisplay) && (
            <UserDashboardNavbar />
          )
        }

        {/* Display the header inside the content if the user is not using the app, but they are on a mobile-sized device */}
        {(!isMobileApp && isMobileDisplay && header) && (
          <div className="px-20 text-center pt-30">
            <Headers.PageHeader className="pb-20 text-grey-900">
              {header.title}
            </Headers.PageHeader>
            <Paragraphs.PublicHeader className="text-grey-650 lg:font-serif tracking-1/4">
              {header.description}
            </Paragraphs.PublicHeader>
          </div>
        )}

        {(isMobileDisplay && featuredImage && featuredImage.search) && (
          <div className="pt-30">
            {featuredImage.search.showTitleOnMobile && (
              <Headers.XL className="mx-auto text-center text-grey-900 pb-30 px-25">
                {featuredImage.title}
              </Headers.XL>
            )}
            {featuredImage.search.element}
          </div>
        )}

        {
          variant === PublicLayoutVariant.Home
            ? children
            : (
              <div>
                {children}
              </div>
            )
        }
      </div>

      <CenteredCustomerLoginModal
        isOpen={centeredModalOpen}
        onClose={() => dispatch(setAuthModal({
          modal: 'centeredLogin',
          open: false,
        }))}
      />

      <CustomerLoginModal
        isOpen={loginModalOpen}
        onClose={() => dispatch(setAuthModal({
          modal: 'login',
          open: false,
        }))}
      />

      <CustomerRegisterModal
        isOpen={registerModalOpen}
        onClose={() => dispatch(setAuthModal({
          modal: 'register',
          open: false,
        }))}
      />

      <CustomerForgottenPasswordModal
        isOpen={forgotPasswordModalOpen}
        onClose={() => dispatch(setAuthModal({
          modal: 'forgotPassword',
          open: false,
        }))}
      />

      {
        variant === PublicLayoutVariant.Public && (
          <>
            <HowCanWeHelpYouSection />

            <PublicFooter className={className?.footer} />
          </>
        )
      }


      {isMobileDisplay && (actionFooter || variant === PublicLayoutVariant.Home)
        ? (
          <ActionFooter
            showNavbar={variant === PublicLayoutVariant.Home}
            {...actionFooter}
          />
        )
        : undefined
      }
    </Tag>
  )
}

export default PublicLayout
