import { useMemo } from 'react'
import { useQuery } from 'react-query'
import ApiClient from 'data/api/api_client'
import { LoadingIcon } from 'components/icons'
import Image from 'components/Image'
import placeholderImage from 'assets/images/placeholder.png'
import { ServiceRequestDetail } from 'gql/graphql'
import PublicMobileModal from 'components/PublicMobileModal'

interface UserServiceRequestModalProps {
  isOpen?: boolean
  onClose?: () => void
  serviceRequestId: string | number | null
}

const UserServiceRequestModal = ({
  isOpen = false,
  onClose = () => { },
  serviceRequestId,
}: UserServiceRequestModalProps) => {
  const { data: serviceRequest, isLoading, isFetching, refetch: refetchServiceRequest }
    = useQuery(['serviceRequest', serviceRequestId],
      () => ApiClient.serviceRequests.getServiceRequest(serviceRequestId),
      {
        enabled: isOpen,
      },
    )

  useMemo(() => {
    if (serviceRequestId && serviceRequestId != serviceRequest?.id) {
      refetchServiceRequest()
    }
  }, [serviceRequestId])

  const closeModal = () => {
    onClose()
  }

  return (
    <PublicMobileModal
      isOpen={isOpen}
      onClose={() => closeModal()}
      className={{
        panel: 'max-w-[1070px] max-h-[800px]',
      }}
    >
      <div className="flex flex-col w-full h-full gap-40 lg:flex-row">
        {(isLoading || isFetching)
          ? <div className="flex items-center justify-center w-full h-full p-50"><LoadingIcon /></div>
          : <div className="flex flex-col lg:flex-row w-full lg:max-h-[800px] gap-x-65">
            <div className="relative w-full lg:max-w-[500px] h-300 lg:h-full">
              <Image
                src={serviceRequest?.service?.service_image ?? placeholderImage}
                className="absolute object-cover w-full"
                layout="fill"
              />
            </div>


            <div className="flex flex-col w-full pb-20 pl-20 pt-55 lg:pl-0">
              <h4 className="pl-5 pr-20 mb-20 font-bold uppercase text-14 leading-20 tracking-1/2 text-grey-750">
                Service Request #{serviceRequest?.request_id.replace('-', '')}
              </h4>

              <h2 className="font-serif font-bold text-[40px] tracking-title leading-title text-grey-900 mb-40 pr-20">
                {serviceRequest?.service?.title}
              </h2>

              <div className="w-full h-full pr-20 overflow-y-auto">
                <p className="pt-20 antialiased text-grey-800 min-h-[140px] text-14 lg:text-15 whitespace-pre-wrap">
                  {(serviceRequest?.details ?? []).map((
                    item: ServiceRequestDetail,
                    index: number,
                  ) =>
                    <span key={index}>
                      <b>{item?.question}</b><br />{item?.answer}<br /><br />
                    </span>,
                  )}
                </p>
              </div>
            </div>
          </div>
        }
      </div>
    </PublicMobileModal>
  )
}

export default UserServiceRequestModal
