import Button, { ButtonProps } from 'components/home/common/Button'
import { useHasScrolled } from 'data/hooks/useHasScrolled'
import { ReactChild } from 'data/types/types'
import UserDashboardNavbar from './UserDashboard/Navbar'
import { useMobileApp } from 'data/hooks/MobileApp/useMobileApp'

export interface ActionFooterProps {
  scrollDelay?: number // Appear after X pixels
  showNavbar?: boolean
  button?: ButtonProps
  element?: ReactChild
}

const ActionFooter = ({
  button,
  element,
  showNavbar,
  scrollDelay,
}: ActionFooterProps) => {
  const { isMobileApp } = useMobileApp()

  const hasScrolled = useHasScrolled(scrollDelay)

  if (scrollDelay && !hasScrolled) {
    return null
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 z-10 flex flex-col justify-center pt-10 bg-white gap-15 pb-safe-or-10">
      {element}

      {
        button && (
          <div className="w-full px-20">
            <Button
              block
              {...button}
            >
              {button?.children}
            </Button>
          </div>
        )
      }

      {
        (isMobileApp || showNavbar) && (
          <UserDashboardNavbar />
        )
      }
    </div>
  )
}

export default ActionFooter
