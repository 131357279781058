import { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useCarouselLoadedImages } from 'data/hooks/useCarouselLoadedImages'
import classNames from 'classnames'
import { useHasLoaded } from 'data/hooks/useHasLoaded'
import { ReactChild } from 'data/types/types'
import CarouselTextContent, { CarouselTextContentProps } from 'components/sections/carousel/CarouselTextContent'
import { FreeMode, Mousewheel } from 'swiper'

type Card = {
  className?: string
  render: (props: { isImageLoaded: boolean }) => ReactChild
}

export interface CardSliderProps extends CarouselTextContentProps {
  isFetching?: boolean
  className?: {
    container?: string,
    superHeading?: string,
    slider?: string,
  } & CarouselTextContentProps['className']
}

interface CardSliderPropsWithCards extends CardSliderProps {
  cards: Card[]
}

const CardSlider = ({
  cards,
  isFetching = false,
  className,
  ...props
}: CardSliderPropsWithCards) => {
  const imagesEitherSide = 6
  const { onRealIndexChange, isImageLoaded } = useCarouselLoadedImages({
    imagesEitherSide,
    imagesLength: cards.length,
  })

  useEffect(() => {
    if (!isFetching && cards.length > 0) {
      onRealIndexChange(0)
    }
  }, [isFetching])

  const { ref: componentRef, hasLoaded } = useHasLoaded()

  return (
    <div className={classNames(
      'w-full antialiased flex flex-col',
      className?.container,
    )}>
      <CarouselTextContent
        {...props}
        isFetching={isFetching}
        variant="mobile"
      />

      <div ref={componentRef}>
        {
          hasLoaded && (
            <Swiper
              modules={[Mousewheel, FreeMode]}
              className={classNames(
                'relative h-full',
                className?.slider,
              )}
              slidesPerView={'auto'}
              spaceBetween={10}
              slidesOffsetBefore={24}
              slidesOffsetAfter={24}
              mousewheel={{
                forceToAxis: true,
              }}
              freeMode={{
                enabled: true,
              }}
              onRealIndexChange={(swiper) => onRealIndexChange(swiper.realIndex)}
            >
              {!isFetching && cards && cards.map((card, index: number) => (
                <SwiperSlide key={index} className={card.className}>
                  {card.render({ isImageLoaded: isImageLoaded(index) })}
                </SwiperSlide>
              ))}
            </Swiper>
          )
        }
      </div>
    </div>
  )
}

export default CardSlider
