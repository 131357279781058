import { ReactChild } from 'data/types/types'
import NextLink, { LinkProps } from 'next/link'
import React from 'react'

interface PropTypes extends Omit<LinkProps, 'onClick'> {
  children: ReactChild | ReactChild[]
  className?: string
  target?: string
  about?: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

const Link = ({
  className,
  children,
  target,
  about,
  onClick,
  ...props
}: PropTypes) => {
  return (
    <NextLink {...props} about={about}>
      <a
        target={target}
        className={className}
        onClick={onClick}
      >
        {children}
      </a>
    </NextLink>
  )
}

export default Link
