import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import useErrorHandler from 'data/hooks/useErrorHandler'
import { useMutation } from 'react-query'
import { useNotifier } from 'react-headless-notifier'
import ApiClient from 'data/api/api_client'
import Notification from 'components/Notification'
import { ModalProps } from 'data/types/modal'
import ShareModal from './modals/home/ShareModal'
import Input from './form/Home/Input'
import Button from './home/common/Button'
import { CheckCircleIcon } from './icons'
import { AxiosError } from 'axios'

interface InviteFriendsModalProps extends ModalProps {}

interface InviteUserFormFields {
  name: string
  email: string
}

const InviteFriendsModal = ({
  isOpen,
  onClose = () => { },
}: InviteFriendsModalProps) => {
  const handleError = useErrorHandler()
  const { notify } = useNotifier()

  const [showConfirmation, setShowConfirmation] = useState(false)

  const { handleSubmit, register, setError, reset, formState: { errors }, watch } = useForm<InviteUserFormFields>()

  const { isLoading, mutate: inviteUser } = useMutation(
    (details: InviteUserFormFields) => ApiClient.users.inviteFriend(details),
    {
      onSuccess: () => {
        setShowConfirmation(true)
      },
      onError: (error: AxiosError) => {
        if (error.response?.status !== 422) {
          notify(
            <Notification message="There was an issue inviting this user, please try again" type="error" />,
          )
        }
        handleError(error, setError)
      },
    },
  )

  const handleOnClose = () => {
    onClose()
  }

  const handleOnOpen = () => {
    reset()
    setShowConfirmation(false)
  }

  return (
    <>
      <ShareModal
        isOpen={isOpen}
        onOpen={handleOnOpen}
        onClose={handleOnClose}
        icon={showConfirmation
          ? <CheckCircleIcon className="mx-auto w-50 h-50 text-primary-fresh" />
          : undefined
        }
        title={showConfirmation
          ? 'INVITATION SENT'
          : 'Invite your friends to join us'
        }
        description={showConfirmation
          ? `${watch('name')} will receive an invitation email shortly!`
          : 'If they book with us, you will automatically receive a 500€ loyalty credit. This will be applied towards your next booking with us.'
        }
      >
        {
          showConfirmation
            ? undefined
            : (
              <form
                onSubmit={handleSubmit((details) => inviteUser(details))}
              >
                <Input
                  {...register('name')}
                  type="text"
                  className={{
                    container: 'mb-10',
                  }}
                  error={errors.name?.message}
                  variant="grey"
                  label="Name"
                  required
                />

                <Input
                  {...register('email')}
                  type="email"
                  className={{
                    container: 'mb-10',
                  }}
                  error={errors.email?.message}
                  variant="grey"
                  label="Email"
                  required
                />
                <hr className="mb-[200px] border-none"/>
                <div className="flex justify-center">
                  <Button
                    type="submit"
                    className={{
                      button: 'max-w-[340px] w-full',
                    }}
                    style="modal"
                    isLoading={isLoading}
                  >
                SEND INVITE
                  </Button>
                </div>
              </form>
            )
        }

      </ShareModal>
    </>
  )
}

export default InviteFriendsModal
