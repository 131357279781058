import React from 'react'
import { LoadingIcon } from 'components/icons'
import classNames from 'classnames'
import { ReactChild } from 'data/types/types'
import Link from './Link'
import { LinkProps } from 'next/link'

interface ButtonPropTypes extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'dangerouslySetInnerHTML' | 'style' | 'children'> {
  isLoading?: boolean
  variant?: 'none' | 'primary' | 'secondary' | 'danger' | 'outline' | 'outline-grey' | 'wishlist' | 'outline-white' | 'white'
  children: ReactChild
  className?: string
  padding?: string
  type?: 'button' | 'submit'
  block?: boolean
  style?: 'customer' | 'customer-secondary' | 'admin'
  loadingClassName?: string
  href?: LinkProps['href']
  target?: '_blank' | '_self'
}

const Button = ({
  children,
  isLoading = false,
  variant = 'primary',
  className = '',
  type = 'button',
  block = false,
  disabled = false,
  style = 'customer',
  loadingClassName,
  href,
  target,
  ...props
}: ButtonPropTypes) => {
  const styleClasses = classNames(
    {
      'border border-primary rounded-4 bg-primary hover:bg-primary-dark hover:border-primary-dark text-white ring-primary ring-opacity-30': variant === 'primary',
      'border border-[#F7F7F7] rounded-4 bg-grey-100 hover:bg-grey-200 hover:border-grey-200 text-grey-800': variant === 'secondary',
      'border border-[#FF0000] rounded-4 bg-[#FF0000] hover:bg-[#E60000] hover:border-[#FF0000]-dark text-white ring-[#FF0000] ring-opacity-30': variant === 'danger',
      'bg-transparent text-primary border-2 border-primary flex items-center justify-center hover:bg-primary-dark hover:border-primary-dark hover:text-white transition ring-primary ring-opacity-30': variant === 'outline',
      'border border-black border-opacity-30 hover:bg-grey-100 text-grey-900 focus:ring-grey-200 text-left': variant === 'outline-grey',
      'w-full flex justify-between h-60 bg-grey-100 hover:bg-grey-250 items-center focus:ring-grey-200 !font-normal !normal-case !tracking-0': variant === 'wishlist',
      'border-2 border-white rounded-[16px] bg-transparent text-white hover:bg-white hover:text-primary': variant === 'outline-white',
      'rounded-[16px] bg-white text-grey-800 hover:bg-primary hover:text-white': variant === 'white',
    },
    {
      'font-bold uppercase px-[28px]': style === 'customer',
      'py-[17px]': style === 'customer' && !isLoading,
      'py-[11px]': style === 'customer' && isLoading || style === 'admin' && !isLoading,
      'px-30 rounded-8': style === 'admin',
      'py-[7px]': style === 'admin' && isLoading,
    },
    'flex items-center justify-center rounded-4 leading-20 focus:outline-none focus:ring-[3px]',
    className,
    {
      'w-full': block,
      'text-16': !className.includes('text-') && style === 'customer',
      'text-[13px]': !className.includes('text-') && style === 'admin',
    },
    {
      'tracking-1/2': style === 'customer',
      'font-medium': style === 'admin',
    },
    {
      'opacity-50 pointer-events-none': disabled && ['primary', 'danger', 'outline-grey'].includes(variant),
      'bg-grey-400 ring-opacity-0 border-transparent hover:bg-grey-400': disabled && ['secondary', 'outline', 'wishlist'].includes(variant),
    },
  )

  const loadingStyleClasses = classNames(
    loadingClassName,
    {
      'p-4': style === 'customer' && !loadingClassName,
      'w-[28px] h-[28px]': style === 'admin',
    },
  )

  if (href) {
    return (
      <Link href={href} target={target} className={styleClasses}>
        {
          isLoading
            ? <LoadingIcon className={loadingStyleClasses} />
            : children
        }
      </Link>
    )
  }

  return (
    <button
      disabled={disabled || isLoading}
      type={type}
      className={styleClasses}
      {...props}
    >
      {
        isLoading
          ? <LoadingIcon className={loadingStyleClasses} />
          : children
      }
    </button >
  )
}

export default Button
