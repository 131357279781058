import { useState, useEffect, React } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import CenteredCustomerLoginModal from 'components/modals/getHelp/CenteredCustomerLoginModal'
import ServiceRequestModal from 'components/modals/ServiceRequestModal'

/**
 * @param {Booking || undefined} booking
 * @returns
 */
const ServiceRequestModalWithAuth = ({
  isOpen,
  service,
  onClose = () => { },
  booking = null,
}) => {
  const user = useSelector(state => state.auth.user)

  const [loginModalOpen, setLoginModalOpen] = useState(false)
  const [serviceModalOpen, setServiceModalOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      if (user) {
        setServiceModalOpen(true)
      } else {
        setLoginModalOpen(true)
      }
    }
  }, [isOpen])

  useEffect(() => {
    if (user && !serviceModalOpen) {
      setServiceModalOpen(true)
    } else {
      if (!loginModalOpen) {
        onClose()
      }
    }
  }, [loginModalOpen])

  const serviceModalClose = () => {
    setServiceModalOpen(false)
    onClose()
  }

  return (
    <>
      {isOpen
        && <>
          {loginModalOpen
            && <CenteredCustomerLoginModal isOpen={loginModalOpen} onClose={() => setLoginModalOpen(false)} />
          }

          {user && serviceModalOpen
            && <ServiceRequestModal
              isOpen={serviceModalOpen}
              onClose={() => serviceModalClose()}
              service={service}
              booking={booking}
            />
          }
        </>
      }
    </>
  )
}

ServiceRequestModalWithAuth.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  service: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  booking: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
}

export default ServiceRequestModalWithAuth
