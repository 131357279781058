import { Tab } from '@headlessui/react'
import Button, { ButtonProps } from 'components/home/common/Button'
import { FacebookIcon, GoogleStyledIcon } from 'components/icons'
import ApiClient from 'data/api/api_client'
import queryCache from 'data/queryCache'
import { ReactChild } from 'data/types/types'
import { FormEventHandler } from 'react'
import { useQuery } from 'react-query'

export enum AuthSectionPanelSsoText {
  Register = 'Sign up with',
  Login = 'Sign in with',
}

export interface AuthSectionPanelProps {
  children: ReactChild
  button?: Omit<ButtonProps, 'children'> & {
    text: string
  }
  socialLogin?: {
    show: boolean
    text: AuthSectionPanelSsoText
  }
  onSubmit?: FormEventHandler<HTMLFormElement>
  buttonStyle: ButtonProps['style']
}

export default function AuthSectionPanel ({
  children,
  button,
  socialLogin,
  onSubmit,
  buttonStyle,
}: AuthSectionPanelProps) {
  const { data: socialLinks } = useQuery(
    queryCache.socialLinks,
    () => ApiClient.auth.getSocialLinks(),
  )

  return (
    <Tab.Panel
      {
        ...(onSubmit
          ? {
            as: 'form',
            onSubmit: onSubmit,
          }
          : {
            as: 'div',
          })
      }
      className="flex flex-col flex-grow gap-20 focus:outline-none"
      tabIndex={-1}
    >
      {children}

      <div className="flex flex-col gap-20 mt-auto">
        {
          socialLogin?.show && socialLinks && (
            <>
              <Button
                href={socialLinks.facebook}
                variant="sso_login"
                style={buttonStyle}
                icon={<FacebookIcon className="text-20 text-[#1877F2]" />}
              >
                {socialLogin.text} Facebook
              </Button>

              <Button
                href={socialLinks.google}
                variant="sso_login"
                style={buttonStyle}
                icon={<GoogleStyledIcon className="text-20" />}
              >
                {socialLogin.text} Google
              </Button>
            </>
          )
        }

        { button && (
          <Button
            className={{
              button: 'justify-self-end',
            }}
            style={buttonStyle}
            {...button}
          >
            {button.text}
          </Button>
        )}
      </div>
    </Tab.Panel>
  )
}
