import { Controller, FieldError } from 'react-hook-form'
import DatePicker from './DatePicker'
import { DayPickerInputProps } from 'react-day-picker'
import { formatDateReadableDatePicker } from '@ama-selections/ui'

interface ControlledDatePickerProps extends DayPickerInputProps {
  name: string
  control: any
  label?: string
  disabled?: boolean
  error: FieldError | undefined
}

export default function ControlledDatePicker ({
  name,
  control,
  label,
  placeholder,
  disabled,
  error,
  ...props
}: ControlledDatePickerProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <DatePicker
          label={label}
          placeholder={placeholder}
          value={value}
          onDayChange={onChange}
          formatDate={formatDateReadableDatePicker}
          inputProps={{
            disabled: disabled,
            ...props.inputProps,
          }}
          dayPickerProps={{
            ...(value ? { selectedDays: value } : {}),
            ...props.dayPickerProps,
          }}
          error={error}
          {...props}
        />
      )}
    />
  )
}
