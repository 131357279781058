import { DragOverlay, defaultDropAnimationSideEffects } from '@dnd-kit/core'
import type { DropAnimation } from '@dnd-kit/core'
import { ReactChild } from 'data/types/types'

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    className: {
      active: 'opacity-40',
    },
  }),
}

export function SortableOverlay ({
  children,
}: {
  children: ReactChild
}) {
  return (
    <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>
  )
}
