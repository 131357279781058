import React, { useMemo, useState } from 'react'
import UserTripServiceShareModal from 'components/booking/trip-schedule/UserTripServiceShareModal'
import { useRouter } from 'next/router'
import { RestBooking } from 'data/types/booking'
import Button from 'components/home/common/Button'

const InviteMoreGuestsTrigger = ({ booking }: { booking: RestBooking }) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  const router = useRouter()

  useMemo(() => {
    if (router?.query?.pending === 'sharetrip') {
      setIsShareModalOpen(true)
    }
  }, [router?.query?.pending])


  return (
    <>
      <UserTripServiceShareModal booking={booking} isOpen={isShareModalOpen} onClose={() => setIsShareModalOpen(false)} />
      <Button
        className={{
          button: '!max-w-[340px]',
        }}
        block
        style="modal"
        onClick={() => setIsShareModalOpen(true)}
      >
        INVITE MORE GUESTS
      </Button>
    </>
  )
}

export default InviteMoreGuestsTrigger
