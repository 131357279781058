import React from 'react'
import TripSummaryViewCardItem from './TripSummaryViewCardItem'
import { TripScheduleService } from 'data/types/bookings/trip_schedule'
import dayjs from 'dayjs'
import { RestBooking } from 'data/types/booking'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

interface TripSummaryViewCardProps {
  services: TripScheduleService[]
  booking: RestBooking
}

const TripSummaryViewCard = ({
  services,
  booking,
}: TripSummaryViewCardProps) => {

  const { selectedDay } = useSelector((state: RootState) => ({
    selectedDay: state.tripSchedule.selectedDay,
  }))

  const splitTime = (time: string) => {
    const splitTime = time.split(':')

    return [
      parseInt(splitTime[0]),
      parseInt(splitTime[1]),
    ]
  }

  return (
    <div className="flex flex-col px-16 gap-y-20">
      {services
        ?.filter(service => dayjs(service.date).format('YYYY-MM-DD') === selectedDay)
        ?.sort((a, b) => {
          const [aHour, aMinute] = a.start_time ? splitTime(a.start_time) : []
          const [bHour, bMinute] = b.start_time ? splitTime(b.start_time) : []
          return aHour === bHour
            ? aMinute - bMinute
            : aHour - bHour
        })
        ?.map(service => {
          return (
            <TripSummaryViewCardItem
              key={service.id}
              service={service}
              booking={booking}
            />
          )
        })
      }
    </div>
  )
}

export default TripSummaryViewCard
