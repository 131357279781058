import { TripScheduleStatus } from 'data/enums/trip-schedule/trip-schedule-status'
import { PaymentStatus, TripScheduleGroup, TripScheduleServiceStatusType } from 'gql/graphql'

const checkInCheckOutTitleOptions = [
  'Check In',
  'Check Out',
]

const hasPayments = (statuses: TripScheduleGroup['payment_statuses']) => {
  return statuses?.some((status) => status === PaymentStatus.Due || status === PaymentStatus.Pending)
}

const hasDuePayments = (statuses: TripScheduleGroup['payment_statuses']) => {
  return statuses?.some((status) => status === PaymentStatus.Due)
}

const hasPendingPayments = (statuses: TripScheduleGroup['payment_statuses']) => {
  return statuses?.some((status) => status === PaymentStatus.Pending)
}

const hasCompletedPayments = (statuses: TripScheduleGroup['payment_statuses']) => {
  return statuses?.some((status) => status === PaymentStatus.Completed)
}

const isDraft = (status: TripScheduleServiceStatusType) => {
  return status === TripScheduleServiceStatusType.Draft || status === TripScheduleServiceStatusType.ConciergeDraft
}

const isPending = (status: TripScheduleServiceStatusType) => {
  return status === TripScheduleServiceStatusType.PendingApproval || status === TripScheduleServiceStatusType.PendingPayment
}

const getTripScheduleServiceStatusType = (status: TripScheduleStatus) => {
  switch (status) {
    case TripScheduleStatus.DRAFT:
      return TripScheduleServiceStatusType.Draft
    case TripScheduleStatus.CONCIERGE_DRAFT:
      return TripScheduleServiceStatusType.ConciergeDraft
    case TripScheduleStatus.PENDING_APPROVAL:
      return TripScheduleServiceStatusType.PendingApproval
    case TripScheduleStatus.PENDING_PAYMENT:
      return TripScheduleServiceStatusType.PendingPayment
    case TripScheduleStatus.CANCELLED:
      return TripScheduleServiceStatusType.Cancelled
    case TripScheduleStatus.CONFIRMED:
      return TripScheduleServiceStatusType.Confirmed
  }
}

export {
  checkInCheckOutTitleOptions,
  // Payments Page
  hasPayments,
  hasDuePayments,
  hasPendingPayments,
  hasCompletedPayments,

  // Trip Schedule Status
  getTripScheduleServiceStatusType,
  isDraft,
  isPending,
}
