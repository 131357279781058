import { useState, useEffect, React } from 'react'
import PropTypes from 'prop-types'
import PublicModal from 'components/PublicModal'
import LogoLink from 'components/logoLink'
import Button from 'components/Button'
import ApiClient from 'data/api/api_client'
import { useMutation, useQuery } from 'react-query'
import BookingSelectListbox from 'components/form/BookingSelectListbox'
import { useSelector } from 'react-redux'
import { useNotifier } from 'react-headless-notifier'
import Notification from 'components/Notification'
import { Widget } from '@typeform/embed-react'
import { BookingSearch } from 'data/enums/booking-search-filters'
import ReadMoreButton from 'components/ReadMoreButton'

const ServiceRequestModal = ({
  isOpen,
  service,
  onClose = () => { },
  booking = null,
}) => {
  const user = useSelector(state => state.auth.user)
  const notify = useNotifier()
  const [selectedBooking, setSelectedBooking] = useState(booking)
  const [firstPage, setFirstPage] = useState(true)

  const { data: bookings, refetch } = useQuery(['user-bookings'],
    () => ApiClient.users.getUserBookings({
      includes: [
        'property.destinationRegion',
        'property.destinationCity',
      ],
      search_filter: BookingSearch.UPCOMING,
      service_id: service.id,
    }),
    { enabled: isOpen && !!user },
    {
      keepPreviousData: true,
    })

  useEffect(() => {
    if (isOpen) {
      if (booking === null) {
        setFirstPage(true)
      }
    }
  }, [isOpen])

  const serviceRequestMutation = useMutation(
    (details) => ApiClient.services.requestService(service.id, selectedBooking.id, {
      response_id: details.responseId,
    }),
    {
      onSuccess: () => {
        notify(
          <Notification message="Your request has been successfully sent!" type="success" />,
        )
      },
      onError: () => {
        notify(
          <Notification message="There was a problem sending your request, please get in contact" type="error" />,
        )
      },
    },
  )

  const getFormID = () => {
    let formLink = service?.form_link ?? ''

    if (formLink?.includes('.typeform.com/to/')) {
      // https://form.typeform.com/to/<form-id> gets the <form-id>
      let parts = formLink.split('/')
      return parts.pop()
    } else if (formLink?.includes('https://admin.typeform.com/')) {
      // https://admin.typeform.com/form/<form-id>/* gets the <form-id>
      let parts = formLink.split('/')
      return parts[parts.length - 2]
    } else {
      return formLink
    }
  }

  useEffect(() => {
    if (service) {
      if (user) {
        refetch()
      }
    }
  }, [user, isOpen])

  return (
    <PublicModal
      isOpen={isOpen}
      onClose={onClose}
      innerPadding={`!p-0 md:!p-40 ${firstPage && !booking ? '!px-30 !py-90 md:!px-40 flex' : 'md:!p-0'}`}
      className={`antialiased transition-all duration-500 ${firstPage && !booking ? 'md:max-w-[452px] md:h-[650px]' : 'rounded-10 max-w-full md:max-w-[90vw]'}`}
      buttonType={firstPage ? 'default' : 'typeform'}
    >
      <div className={`${firstPage && !booking ? '' : 'h-[90vh]'}`}>
        {firstPage && !booking
          ? <div className="flex flex-col items-center my-auto">
            <LogoLink className="max-w-[130px] w-full mb-35" />
            <p className="mb-10 font-bold text-16 leading-24 -tracking-1/2">Which booking is this request for?</p>
            <p className="font-normal text-center text-grey-500 text-14 leading-24 -tracking-1/2 mb-50">
              This request will be sent directly to your dedicated concierge who will follow up with you if needed. Once confirmed, your trip itinerary will be automatically updated.
            </p>

            <BookingSelectListbox
              bookings={bookings?.data}
              selectedBooking={selectedBooking}
              setSelectedBooking={setSelectedBooking}
              emptyStateText={bookings?.meta && bookings?.meta?.booking_count > 0
                ? 'This is isn\'t available in the location you booked'
                : 'You must have a booking to send this request'
              }
            />

            <div className="w-full px-2 mt-90">
              {service?.survey_slug
                ? <ReadMoreButton
                  href={`/home/reservations/${selectedBooking?.id}/service-request/${service.survey_slug}`}
                  className="justify-center font-bold text-18"
                  icon={null}
                  disabled={!selectedBooking}
                  variant="primary"
                >
                  NEXT
                </ReadMoreButton>
                : <Button onClick={() => setFirstPage(false)} className="w-full font-bold text-18" disabled={!selectedBooking}>NEXT</Button>
              }
            </div>
          </div>
          : <>
            {isOpen
              && <div className="w-full h-full max-h-screen rounded-10 md:max-h-full">
                <div className="h-55 rounded-t-10 lg:hidden" />

                <div className="h-[calc(100%-55px)] lg:h-full">
                  <Widget
                    id={getFormID()}
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    hidden={{
                      email: user.email,
                      booking_id: selectedBooking?.id,
                    }}
                    onSubmit={(event) => {
                      serviceRequestMutation.mutate(event)
                      onClose()
                    }}
                    keepSession={false}
                  />
                </div>
              </div>
            }
          </>
        }
      </div>

    </PublicModal>
  )
}

ServiceRequestModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  service: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  booking: PropTypes.object,
}

export default ServiceRequestModal
