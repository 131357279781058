import { useQuery } from 'urql'
import { isServer } from 'data/helpers/ssr'
import { graphql } from 'gql'
import { ContinentsOrderByColumn, SortOrder } from 'gql/graphql'
import PillSwiper from 'components/home/PillSwiper'
import { useMemo } from 'react'

interface DestinationModalContinentListProps {
  pause: boolean
  continentId: string | undefined
  setContinentId: (continentId?: string) => void
  className?: {
    container?: string
  }
  slideOffset?: number
}

const DestinationModalContinentList = ({
  pause,
  continentId,
  setContinentId,
  className,
  slideOffset = 40,
}: DestinationModalContinentListProps) => {
  const [{ data, fetching }] = useQuery({
    query: graphql(`
      query DestinationModalContinentList ($orderBy: [OrderByClause!]) {
        continents (orderBy: $orderBy) {
          id
          value
          countries(first: 1) {
            paginatorInfo {
              total
            }
          }
        }
      }
    `),
    variables: {
      orderBy: [{
        column: ContinentsOrderByColumn.Value,
        order: SortOrder.Asc,
      }],
    },
    pause: pause && isServer,
  })

  const continents = useMemo(() => {
    const continentsData = data?.continents
      .filter(continent => continent.countries.paginatorInfo.total > 0)
      .map(continent => ({
        id: continent.id,
        value: continent.value,
      })) ?? []

    return [
      {
        id: undefined,
        value: 'All',
      },
      ...continentsData,
    ]
  }, [data])

  return (
    <PillSwiper
      className={className}
      selected={continentId
        ? [parseInt(continentId)]
        : []
      }
      data={continents}
      onClick={e => setContinentId(e?.toString())}
      fetching={fetching}
      slideOffset={slideOffset}
      variant="blue"
    />
  )
}

export default DestinationModalContinentList
