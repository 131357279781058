import { joinBy } from '@ama-selections/ui'
import classNames from 'classnames'
import ProfileImage from 'components/ProfileImage'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import { TickIcon } from 'components/icons'
import { InvitedWishlistUser, RestSharedUserInvite, RestUser } from 'data/types/user'
import { User } from 'gql/graphql'
import { useMemo } from 'react'

interface SharedUserProps {
  user: RestUser | RestSharedUserInvite | User | InvitedWishlistUser
  label: string
  showTick?: boolean
  onClick?: (user: RestUser | RestSharedUserInvite | User | InvitedWishlistUser) => void
  variant?: 'wishlist'
}

export const SharedUser = ({
  user,
  label,
  showTick = false,
  onClick,
}: SharedUserProps) => {
  const hasFullAccess = useMemo(() => {
    if ('has_full_access' in user) {
      return user.has_full_access
    }

    return false
  }, [user])

  return (
    <button
      onClick={() => onClick?.(user)}
      className="flex items-center justify-between gap-20"
      disabled={!onClick}
    >
      <ProfileImage
        user={user}
        className="h-42 w-42"
        textClassName="text-18"
      />

      <div
        className="flex flex-col flex-1 text-left"
      >
        <h5 className="-tracking-1/2 text-14 !font-sans !font-medium text-blue-1000 leading-24">
          {joinBy([(user as RestUser).first_name, (user as RestUser).last_name], ' ')}
        </h5>

        <Paragraphs.emailCaption className="leading-24 text-grey-500">
          {user.email}
        </Paragraphs.emailCaption>
      </div>

      <div className={classNames(
        'flex h-max items-center justify-center px-14 py-4 rounded-10 gap-[7px]',
        'font-poppins font-medium tracking-1/4 leading-22 text-12',
        {
          '!bg-green-800': showTick,
          'bg-grey-150': !hasFullAccess,
          'bg-primary-200': hasFullAccess,
        })}
      >
        {showTick && <TickIcon className="w-9 h-7" />}
        {label}
      </div>
    </button>
  )
}

export default SharedUser
