import React, { createRef, useCallback, useEffect, useState } from 'react'
import Button from 'components/Button'
import { BinIcon, LoadingIcon, UploadIcon } from 'components/icons'
import ApiClient from 'data/api/api_client'
import { useNotifier } from 'react-headless-notifier'
import Notification from 'components/Notification'
import Image from 'components/Image'
import classNames from 'classnames'
import { ReactChild } from 'data/types/types'
import { SortableList } from 'components/drag-n-drop/SortableList'
import { config } from 'data/config'
import unionBy from 'lodash/unionBy'

const MultiImageItem = ({
  url,
  onRemove,
}: {
  url: string,
  onRemove: () => void
}) => {
  return (
    <div className="relative max-h-[145px] max-w-[160px] m-8">
      <div className="relative h-[25vw] sm:h-[145px] w-[25vw] sm:w-[160px]">
        <Image
          src={url}
          className="object-cover rounded-4"
          layout="fill"
          priority
          sizes="10vw"
        />
      </div>

      <button
        type="button"
        className="absolute p-5 bg-white top-5 right-5 bg-opacity-70 text-12 rounded-8 hover:bg-opacity-90"
        onClick={onRemove}
      >
        <BinIcon />
      </button>
    </div>
  )
}

type Item = {
  id: number|string,
  url: string,
  temp: boolean,
}

const MultipleImageUpload = ({
  header,
  value = [],
  onChange = () => { },
  error,
  model,
  onLoadingChange = () => { },
}: {
  header?: ReactChild,
  value: {
    id: number,
    url: string,
  }[],
  onChange: (value: any) => void,
  error?: string,
  model: {
    id: string,
    type: string,
  },
  onLoadingChange: (value: boolean) => void,
}) => {
  const { notify } = useNotifier()
  const fileRef = createRef<HTMLInputElement>()
  const [items, setItems] = useState<Item[]>([])

  const [uploadingImages, setUploadingImages] = useState(false)

  const handleClick = () => {
    if (fileRef.current === null) { return }
    fileRef.current.click()
  }

  useEffect(() => {
    if (value.length === 0 && items.length > 0) {
      setItems([])
    } else if (value.length > 0) {
      setItems(value.map((i) => ({
        id: i.id,
        url: i.url,
        temp: false,
      })))
    }
  }, [value?.length])

  const handleFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null) { return }

    const newItems = [...items]
    const maxOrder = newItems.length ?? 1

    const { compare } = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

    const newFiles = Array.from(e.target.files)
      .sort((a, b) => compare(a.name, b.name))

    const urls = newFiles
      .map(file => (file instanceof File) ? URL.createObjectURL(file) : undefined)
      .filter((url): url is string => url !== undefined)

    setUploadingImages(true)

    Promise
      .all(newFiles.map((file, index) => ApiClient.files.uploadFile(
        file,
        model,
        true,
        maxOrder + index,
      )))
      .then(files => {
        files
          .sort((a, b) => compare(a.file_name, b.file_name))
          .forEach((file: { id: number, temp: boolean, file_name: string }, index) => {
            newItems.push({
              id: file.id,
              url: urls[index],
              temp: file.temp ?? false,
            })
          })

        notify(
          <Notification type="success" message="Images Uploaded" />,
        )

        setUploadingImages(false)
      })

    setItems(newItems)
    onChange(newItems)
  }, [value, items, onChange, setUploadingImages, model, notify])

  const [itemToAdd, setItemToAdd] = useState<Item | null>(null)
  const [itemToRemove, setItemToRemove] = useState<Item | null>(null)

  useEffect(() => {
    if (itemToAdd) {
      const newItems = unionBy([itemToAdd], items, 'id')
      setItems(newItems)
      onChange(newItems)
      setItemToAdd(null)
    }
  }, [itemToAdd])

  useEffect(() => {
    if (itemToRemove) {
      const newItems = items.filter((i) => i.id !== itemToRemove.id)
      setItems(newItems)
      onChange(newItems)
      setItemToRemove(null)
    }
  }, [itemToRemove])

  const removeFile = useCallback((itemToRemove: Item) => {
    setItemToRemove(itemToRemove)
    setUploadingImages(true)

    Promise
      .resolve(ApiClient.files.deleteFile(itemToRemove.id, itemToRemove.temp))
      .catch(() => {
        notify(<Notification type="error" message="Error Deleting Image" />)
        setItemToAdd(itemToRemove)

        return Promise.reject()
      })
      .then(() => {
        notify(<Notification type="success" message="Image Deleted" />)
      })
      .finally(() => setUploadingImages(false))
  }, [items, setItems, onChange, setUploadingImages, notify])

  const ErrorMessage = error
    ? <span className="text-red text-14">{error}</span>
    : null

  useEffect(() => {
    onLoadingChange(uploadingImages)
  }, [uploadingImages])

  return (
    <div>
      {
        header
          ? typeof header === 'string'
            ? <p className="mb-10 w-max">{header}</p>
            : header
          : null
      }

      <div className="w-full">
        <div>
          {
            items.length || (value && value.length)
              ? (
                <>
                  <SortableList
                    items={items}
                    onChange={(newItems: Item[]) => {
                      setItems(newItems)
                      onChange(newItems)
                    }}
                    container={(children: ReactChild) => (
                      <div className="flex flex-wrap w-full">
                        {children}
                      </div>
                    )}
                    renderItem={(item) => (
                      <SortableList.Item id={item.id}>
                        <MultiImageItem
                          url={item.url}
                          onRemove={() => removeFile(item)}
                        />
                      </SortableList.Item>
                    )}
                  />

                  <div>
                    <button type="button" className="max-h-[145px] w-full border border-dashed border-primary rounded-20 text-50 text-grey-750 py-20" onClick={handleClick}>
                      <div className="flex items-center justify-center h-full">
                        {
                          uploadingImages
                            ? <LoadingIcon />
                            : <UploadIcon />
                        }
                      </div>
                    </button>
                  </div>

                  {ErrorMessage}
                </>
              )
              : <>
                <div className={classNames(
                  'h-full flex flex-col items-center justify-center gap-20 p-20 border border-dashed rounded-20',
                  {
                    'border-primary': !error,
                    'border-red': error,
                  },
                )}>
                  <span className="text-50 text-grey">
                    {
                      uploadingImages
                        ? <LoadingIcon />
                        : <UploadIcon />
                    }
                  </span>
                  <Button style="admin" onClick={handleClick} variant="outline">Choose Files</Button>
                  {ErrorMessage}
                </div>
              </>
          }

        </div>
      </div>

      <input
        type="file"
        ref={fileRef}
        className="hidden"
        multiple
        onChange={handleFileChange}
        accept={config.acceptedImageTypes}
      />
    </div>
  )
}

export default MultipleImageUpload
