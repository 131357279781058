import React from 'react'
import { FieldError } from 'react-hook-form'
import ServiceRequestSlide from 'components/service-request-forms/components/ServiceRequestSlide'
import ServiceRequestQuestion from 'components/service-request-forms/components/ServiceRequestQuestion'
import ServiceRequestNextButton from 'components/service-request-forms/components/ServiceRequestNextButton'
import ServiceSurveyForm from 'components/form/ServiceSurveyForm'
import { QuestionText } from 'data/helpers/survey/surveys/car-rental'
import { CarRentalError, SurveyFormProps } from 'data/types/service_surveys'
import { getValue } from 'data/enums/survey/general'
import { SurveyYesNo } from 'gql/graphql'

const Slide5 = ({
  questionStartIndex,
  position,
  fetching,
  isSlideActive,
  control,
  formState: { errors },
  setValue,
  clearErrors,
}: SurveyFormProps) => {
  return (
    <ServiceRequestSlide
      slideNumber={5}
      position={position}
      isSlideActive={isSlideActive}
      setValue={setValue}
      clearErrors={clearErrors}
    >
      <ServiceRequestQuestion
        questionNumber={questionStartIndex}
        question={QuestionText(7)}
      />
      <ServiceSurveyForm.ControlledRadioGroup
        name="question7.need_child_seat"
        control={control}
        options={[
          { value: getValue(SurveyYesNo.Yes) },
          { value: getValue(SurveyYesNo.No) },
        ]}
        disabled={!isSlideActive}
        error={((errors as CarRentalError)?.question7 as any as { need_child_seat?: FieldError })?.need_child_seat}
      />
      <ServiceRequestNextButton
        isLoading={fetching}
        disabled={!isSlideActive}
      />
    </ServiceRequestSlide>
  )
}

export default Slide5
