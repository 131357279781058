import { useFormContext } from 'react-hook-form'
import { GetHelpFormProps, InquiryDetailsFormFields } from './GetHelpModal'
import Form from 'components/form/Home'
import ControlledDateRangePickerModal from 'components/dates/ControlledDateRangePickerModal'
import { CalendarSearchBarIcon } from 'components/icons'
import { formatDateReadable } from '@ama-selections/ui'
import dayjs from 'dayjs'
import Button from 'components/home/common/Button'

interface GetHelpGeneralFormProps extends GetHelpFormProps {
  onIsOpenChange: (isOpen: boolean) => void
}

const GetHelpGeneralForm = ({
  isLoading,
  onSubmit,
  onIsOpenChange,
}: GetHelpGeneralFormProps) => {
  const { register, control, formState: { errors, isSubmitting }, handleSubmit, watch, setValue } = useFormContext<InquiryDetailsFormFields>()

  return (
    <form
      id="general-inquiry-form"
      className="flex flex-col h-full gap-20"
      onSubmit={handleSubmit(onSubmit)}
    >
      <ControlledDateRangePickerModal
        onIsOpenChange={onIsOpenChange}
        renderButton={({ onClick }) => (
          <div className="flex gap-20">
            <Form.Input
              variant="searchbar"
              prefix={<CalendarSearchBarIcon />}
              placeholder="Arrival"
              readOnly
              value={formatDateReadable(watch('dates.startDate')) ?? ''}
              onClick={onClick}
            />

            <Form.Input
              variant="searchbar"
              prefix={<CalendarSearchBarIcon />}
              placeholder="Departure"
              readOnly
              value={formatDateReadable(watch('dates.endDate')) ?? ''}
              onClick={onClick}
            />
          </div>
        )}
        datePickerProps={{
          control: control,
          name: 'dates',
          disabledDates: [{
            before: dayjs().toDate(),
            after: dayjs().add(1, 'year').toDate(),
          }],
        }}
        selectedDates={watch('dates')}
        onClear={() => {
          setValue('dates.startDate', undefined)
          setValue('dates.endDate', undefined)
        }}
        error={errors.dates?.message}
      />

      <div className="flex flex-col gap-20 sm:flex-row">
        <Form.Input
          variant="grey"
          placeholder="Number Of Guests"
          {...register('guests')}
          error={errors?.guests?.message}
        />
        <Form.Input
          variant="grey"
          placeholder="Your Budget"
          {...register('budget')}
          error={errors?.budget?.message}
        />
      </div>
      <div className="flex flex-col flex-1">
        <Form.Label className="mb-[9px] text-grey-800 !text-14">Message</Form.Label>
        <Form.Textarea
          placeholder="Let us know when and where you'd like to travel to and what the occasion is ..."
          {...register('message')}
          error={errors?.message}
          className={{
            container: 'flex-1',
            textarea: 'h-full',
            wrapper: 'h-full',
          }}
        />
      </div>

      <Button
        style="modal"
        type="submit"
        isLoading={isSubmitting || isLoading}
        disabled={isSubmitting}
      >
        Send Message
      </Button>

    </form>
  )
}

export default GetHelpGeneralForm
