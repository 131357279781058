import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  AddCircleIcon,
  CrossIcon,
  FilterSearchBarIcon,
  LoadingIcon,
  MapPinIcon,
  MinusCircleIcon,
  SearchAltIcon,
} from 'components/icons'
import ArrivalDeparturePopover from 'components/popovers/ArrivalDeparturePopover'
import GuestsPopover from 'components/popovers/GuestsPopover'
import Input from 'components/form/Input'
import { useRouter } from 'next/router'
import Link from 'components/Link'
import { useScreenWidth } from 'data/hooks/useScreenWidth'
import { useDispatch, useSelector } from 'react-redux'
import CustomerProfileModal from 'components/modals/CustomerProfileModal'
import { getDestinationSearchURL } from 'data/helpers/destination'
import MobilePublicNavbar from 'components/mobile/MobilePublicNavbar'
import { setDestinationsOpen } from 'store/searchbar'
import Button from 'components/Button'
import { gql, useQuery } from 'urql'
import { formatDateReadable, parseDate } from 'data/helpers/dates'
import { isServer } from 'data/helpers/ssr'
import SearchResults from 'components/popovers/DestinationSelectionPopover/_SearchResults'
import { useDestinationSearch } from 'data/hooks/Destinations/useDestinationSearch'
import { useDestinationFetching } from 'data/hooks/Destinations/useDestinationFetching'

const DestinationDropdownSearchItem = ({ value = '' }) => {
  return (
    <div className="flex flex-row items-center gap-20 antialiased cursor-pointer mt-15">
      <div className="flex items-center justify-center rounded-full w-30 h-30 bg-grey-150">
        <MapPinIcon />
      </div>
      <div className="font-bold text-14 text-grey-800">
        {value}
      </div>
    </div>
  )
}

DestinationDropdownSearchItem.propTypes = {
  value: PropTypes.string,
}

const MobileSearchbarContent = ({ isHomepage, selectedDestination, setSelectedDestination, closeable = false, onClose = () => { } }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { width } = useScreenWidth()

  const { destinationsOpen, searchingDestinations } = useSelector(state => ({
    destinationsOpen: state.searchbar.destinationsOpen,
    searchingDestinations: state.searchbar.searchingDestinations,
  }))

  const { results: destinationSearchResults } = useDestinationSearch(selectedDestination.value)

  const { isDestinationFetching } = useDestinationFetching()

  useEffect(() => {
    closePopovers()
  }, [router?.query])

  const [arrival, setArrival] = useState(router?.query?.arrival)
  const [departure, setDeparture] = useState(router?.query?.departure)

  const [numBedrooms, setNumBedrooms] = useState(router?.query?.bedrooms ? Number(router?.query?.bedrooms) : 0)
  const [numAdults, setNumAdults] = useState(router?.query?.adults ? Number(router?.query?.adults) : 0)
  const [numChildren, setNumChildren] = useState(router?.query?.children ? Number(router?.query?.children) : 0)


  const [guestsSelectorPopoverOpen, setGuestsSelectorPopoverOpen] = useState(false)
  const [arrivalDeparturePopoverOpen, setArrivalDeparturePopoverOpen] = useState(false)
  const [filtersPopoverOpen, setFiltersPopoverOpen] = useState(false)

  const [visible, setVisible] = useState(false)
  const [viewAllOpen, setViewAllOpen] = useState(false)
  const [profileModalOpen, setProfileModalOpen] = useState(false)

  useEffect(() => {
    if (destinationsOpen) {
      setArrivalDeparturePopoverOpen(false)
      setGuestsSelectorPopoverOpen(false)
    }
  }, [destinationsOpen])

  useEffect(() => {
    if (guestsSelectorPopoverOpen) {
      setArrivalDeparturePopoverOpen(false)
      dispatch(setDestinationsOpen(false))
    }
  }, [guestsSelectorPopoverOpen])

  useEffect(() => {
    if (arrivalDeparturePopoverOpen) {
      dispatch(setDestinationsOpen(false))
      setGuestsSelectorPopoverOpen(false)
    }
  }, [arrivalDeparturePopoverOpen])

  const handleScroll = () => {
    if (!isHomepage) {
      setVisible(window.scrollY > 150)
    }
  }

  useEffect(() => {
    if (typeof window === 'undefined') {return}
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const getText = (num, text) => {
    return `${num} ${text}${num === 1 ? '' : 's'}`
  }

  const [{ data, fetching }] = useQuery({
    query: gql`
      query DestinationSelectionPopoverData {
        countries(first: 100) {
          data {
            id
            value
            slug
          }
        }
        regions(first: 100) {
          data {
            id
            value
            slug
            country {
              id
              value
            }
          }
        }
        subRegions(first: 100) {
          data {
            id
            value
            slug
            region {
              id
              value
              country {
                id
                value
              }
            }
          }
        }
        featuredRegions(first: 4) {
          data {
            id
            value
            slug
            country {
              id
              value
              slug
            }
          }
        }
      }
    `,
    pause: isServer,
  })

  const closePopovers = () => {
    if (!closeable) {
      dispatch(setDestinationsOpen(false))
    }
    setViewAllOpen(false)
    setFiltersPopoverOpen(false)
  }

  const DestinationSearchResults = () => {
    const destinations = data?.countries?.data?.map((country) => {
      return {
        ...country,
        regions: data?.regions?.data?.filter((region) => region.country.id === country.id).map((region) => {
          return {
            ...region,
            subregions: data?.subRegions?.data?.filter((subregion) => subregion.region.id === region.id),
          }
        }),
      }
    }).filter(country => (
      country.regions.length > 0
    ))

    return (
      <>
        {
          destinationSearchResults
          && <SearchResults
            onClick={(data) => {
              if (!closeable) {
                dispatch(setDestinationsOpen(false))
              }

              setSelectedDestination(data)
            }}
            destinations={destinationSearchResults}
          />
        }
        <div className="flex flex-col gap-20">
          <div className="flex flex-row justify-between align-middle">
            <div className="text-grey-500 whitespace-nowrap text-14 leading-16">Popular Destinations</div>
          </div>
          {data?.featuredRegions?.data?.map((region, index) => (
            <Link key={index} href={`/${region.country.slug}/${region.slug}`} className="flex flex-col gap-20">
              <div className="flex flex-row items-center w-full gap-20 ">
                <div className="flex items-center justify-center rounded-full w-30 h-30 bg-grey-150">
                  <MapPinIcon />
                </div>
                <div className="flex flex-col content-center">
                  <div className={`text-14 ${index == 0 ? 'text-primary' : null} `}>
                    {region.value}
                  </div>
                  <div className="truncate text-grey-500 text-12">
                    {data?.countries?.data?.filter(country => country.id == region.country.id)[0].value}
                  </div>
                </div>
              </div>
            </Link>
          ),
          )}
        </div>
        <div className="w-full">
          <div className="flex justify-between w-full">
            <h3 className="text-grey-500 text-14 leading-16">All Destinations</h3>
            <button type="button" className="cursor-pointer" onClick={() => setViewAllOpen(!viewAllOpen)}>
              <h3 className={`text-14 text-primary ${viewAllOpen ? 'hidden' : 'block'} `}>View all</h3>
              <h3 className={`text-14 text-primary ${viewAllOpen ? 'block' : 'hidden'} `}>View less</h3>
            </button>
          </div>

          <div className="grid flex-row w-full grid-cols-1 gap-40 mt-20 md:grid-cols-2">
            {destinations?.map(country => (
              <div key={country.id}>
                <Link
                  className="font-serif text-24 text-grey-900 leading-30 tracking-[-0.09px] mb-15 xl:mb-0"
                  href={`/${country.slug}`}
                >
                  {country.value}
                </Link>
                <div className={`flex flex-col ${viewAllOpen ? 'gap-y-[17px]' : ''}`}>
                  {country.regions?.map(region => (
                    <div className="flex flex-col" key={region.id} >
                      <Link href={`/${country.slug}/${region.slug}`} className={`text-14 text-grey-900 leading-23 ${viewAllOpen ? 'mb-[13px]' : ''}`}>
                        {region.value}
                      </Link>
                      {viewAllOpen
                        && region.subregions?.map(subregion => (
                          <div key={subregion.id}>
                            <Link href={`/${country.slug}/${region.slug}/${subregion.slug}`} className="mb-8 text-14 leading-23 text-grey-650">
                              {subregion.value}
                            </Link>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    )
  }

  return (
    <div className={`z-20 justify-center flex transition-opacity ${isHomepage
      ? 'opacity-100 fixed top-0 left-0 right-0'
      : `${visible ? 'opacity-100' : filtersPopoverOpen || destinationsOpen ? 'opacity-100 fixed top-0 left-0 right-0' : 'opacity-0 hidden'}`}`}>
      {filtersPopoverOpen && <div className="fixed inset-0" onClick={() => setFiltersPopoverOpen(true)} />}
      <div className={'overlay shadow-public-searchbar rounded-none z-10 bg-white'}>
        <div className="w-full h-full max-h-screen px-10 overflow-auto antialiased">
          <div className="w-full py-10 h-[70px] flex sticky top-0 bg-white z-50">
            <div className="flex-grow rounded-l-full cursor-pointer bg-grey-100">
              <span className={'focus:outline-none w-full flex items-center whitespace-nowrap pl-20 h-50'}>
                <button
                  type="button"
                  className="cursor-pointer text-20 text-primary"
                  onClick={() => {
                    if (!closeable) {
                      dispatch(setDestinationsOpen(!destinationsOpen))
                    }
                  }}
                >
                  <MapPinIcon />
                </button>
                <Input
                  onFocus={() => dispatch(setDestinationsOpen(true))}
                  withRing={false}
                  style="customer"
                  className="w-full placeholder-grey-500 text-grey-800 text-16 !h-50"
                  placeholder="Destinations"
                  value={selectedDestination?.value}
                  onChange={(e) => setSelectedDestination({ fromDropdown: false, value: e.target.value })}//Maybe search through regions and subregions?
                />
                {
                  searchingDestinations
                  && <LoadingIcon className="p-8" />
                }
              </span>
            </div>

            <button
              type="button"
              className={`rounded-r-full w-80 h-50 flex justify-center py-15 cursor-pointer border ${filtersPopoverOpen ? 'bg-white border-grey-150 text-black text-opacity-40' : 'bg-primary border-primary text-white'}`}
              onClick={() => setFiltersPopoverOpen(!filtersPopoverOpen)}
            >
              <FilterSearchBarIcon />
            </button>

            <div className="py-10 pl-10 pr-5 my-auto">
              {
                closeable
                  ? <button type="button" onClick={() => onClose()}>
                    <CrossIcon />
                  </button>
                  : <MobilePublicNavbar variant="nologo" />
              }
            </div>
          </div>

          <div className={`flex flex-col gap-40 py-25 px-20 ${destinationsOpen ? 'block' : 'hidden'} ${closeable ? 'pb-120' : ''} relative`}>
            {
              !closeable
              && <button className="absolute right-0 w-20 h-20 cursor-pointer top-5 text-grey-800" onClick={() => closePopovers()}>
                <CrossIcon />
              </button>
            }
            {
              isServer || fetching
                ? (
                  <div className="h-[calc(100vh-120px)]">
                    <LoadingIcon className="p-8 mx-auto" />
                  </div>
                )
                : <DestinationSearchResults />
            }
          </div>

          <div className={`flex-col gap-10 px-15 py-25 w-full ${filtersPopoverOpen ? 'flex' : 'hidden'}`}>
            <div className="flex flex-col gap-10 md:flex-row">
              <div className={`${width > 768 ? 'border' : 'border-none'} border-grey-150 w-full`}>
                <ArrivalDeparturePopover
                  arrival={arrival ? parseDate(arrival).toDate() : null}
                  setArrival={arrival => setArrival(arrival ? formatDateReadable(arrival) : null)}
                  departure={departure ? parseDate(departure).toDate() : null}
                  setDeparture={departure => setDeparture(departure ? formatDateReadable(departure) : null)}
                  position="mt-[1px] absolute sm:inset-x-25 md:right-[calc(50%+5px)] z-50"
                  setIsOpen={setArrivalDeparturePopoverOpen}
                  isOpen={arrivalDeparturePopoverOpen}
                />
              </div>
              <div className={`${width > 768 ? 'border' : 'border-none'} border-grey-150 w-full relative`}>
                <GuestsPopover
                  className="text-center"
                  numBedrooms={numBedrooms} setNumBedrooms={bedrooms => setNumBedrooms(bedrooms)}
                  numAdults={numAdults} setNumAdults={adults => setNumAdults(adults)}
                  numChildren={numChildren} setNumChildren={children => setNumChildren(children)}
                  open={guestsSelectorPopoverOpen}
                  setIsOpen={setGuestsSelectorPopoverOpen}
                  variant="local"
                  position={destinationsOpen ? 'absolute bottom-60' : 'fixed top-[230px] md:top-[160px] md:left-[calc(50%+5px)]  md:w-[calc(50%-30px)]'}
                >
                  <button className={`${guestsSelectorPopoverOpen ? 'border-primary' : 'border-transparent'} h-full focus:outline-none flex flex-row items-center gap-20 w-full justify-between px-30`} onClick={() => setGuestsSelectorPopoverOpen(!guestsSelectorPopoverOpen)}>
                    <MinusCircleIcon className="rounded-full text-24 text-grey-250" />
                    <p className="antialiased text-center text-grey-800">
                      {
                        numAdults || numChildren
                          ? getText(numAdults + numChildren, 'Guest')
                          : <span className="text-grey-500">Guests</span>
                      }
                    </p>
                    <AddCircleIcon className="rounded-full text-24 text-primary" />
                  </button>
                </GuestsPopover>
              </div>
            </div>

            <div className={`border-none w-full h-[49px] justify-self-end ${destinationsOpen && 'mb-100'}`}>
              <div>
                <Link href={getDestinationSearchURL(
                  selectedDestination.country?.slug,
                  selectedDestination.region?.slug,
                  selectedDestination.subregion?.slug,
                  arrival,
                  departure,
                  numBedrooms,
                  numAdults,
                  numChildren,
                )}>
                  <Button
                    className="h-60 w-full !text-18 gap-10 !p-0"
                    isLoading={isDestinationFetching}
                  >
                    <>
                      <SearchAltIcon />
                      <p className="text-16">Search</p>
                    </>
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomerProfileModal isOpen={profileModalOpen} onClose={() => setProfileModalOpen(false)} />
    </div>
  )
}

MobileSearchbarContent.propTypes = {
  isHomepage: PropTypes.bool,
  selectedDestination: PropTypes.object,
  setSelectedDestination: PropTypes.func,
  closeable: PropTypes.bool,
  onClose: PropTypes.func,
}

export default MobileSearchbarContent
