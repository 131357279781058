import { SurveyFormProps } from 'data/types/service_surveys'
import { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { ServiceSurvey } from 'data/enums/service-survey'

interface ServiceRequestSlideProps extends Pick<SurveyFormProps, 'position' | 'isSlideActive' | 'setValue' | 'clearErrors'> {
  children: JSX.Element | JSX.Element[]
  slideNumber: number
}

const ServiceRequestSlide = ({
  children,
  slideNumber,
  position,
  isSlideActive,
  setValue,
  clearErrors,
}: ServiceRequestSlideProps) => {
  const router = useRouter()

  const wideSurveys = [ServiceSurvey.PRE_STOCKING]

  const isWideView = useMemo(() => {
    const surveyType = router.pathname.replace('/home/reservations/[id]/service-request/forms/', '') as ServiceSurvey

    return wideSurveys.includes(surveyType)
  }, [router.pathname])

  useEffect(() => {
    if (isSlideActive) {
      setValue('slide_number', slideNumber)
      setValue('position', position)
    }
    clearErrors()
  }, [isSlideActive])

  return (
    <div className={classNames('flex flex-col justify-center my-auto px-20 pt-20 pb-20 overflow-y-auto w-full gap-y-37 min-h-full', {
      'max-w-[880px]': isWideView,
      'max-w-[698px]': !isWideView,
    })}>
      {children}
    </div>
  )
}

export default ServiceRequestSlide
