import classNames from 'classnames'
import { ComponentProps } from 'react'

interface PropTypes extends ComponentProps<'p'> {
  Tag?: 'p' | 'span' | 'div'
}

const XXS = ({ children, className = '', Tag = 'p', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      className,
      'text-11 leading-14',
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const XS = ({ children, className = '', Tag = 'p', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      className,
      'text-12 lg:text-14 leading-14',
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const SM = ({ children, className = '', Tag  = 'p', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      className,
      'text-13 md:text-14 lg:text-15 leading-16 md:leading-18 lg:leading-20',
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const MD = ({ children, className = '', Tag  = 'p', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      className,
      'text-14 leading-18',
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const LG = ({ children, className = '', Tag  = 'p', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      className,
      'text-15 leading-18',
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const XL = ({ children, className = '', Tag  = 'p', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      className,
      'text-16 leading-20',
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const XXL = ({ children, className = '', Tag  = 'p', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      className,
      'text-18 leading-20',
    )}
    {...rest}
  >
    {children}
  </Tag>
)

// Custom
const PropertyPage = ({ children, className = '', Tag = 'p', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      className,
      'text-14 lg:text-15 leading-23',
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const PublicHeader = ({ children, className = '', Tag = 'p', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      className,
      'text-13 leading-20 lg:text-18 lg:leading-22',
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const BookingCaption = ({ children, className = '', Tag = 'p', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      className,
      'text-12 tracking-1/2 leading-20 uppercase font-poppins',
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const BookingContent = ({ children, className = '', Tag = 'p', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      className,
      'text-14 tracking-default leading-20',
    )}
    {...rest}
  >
    {children}
  </Tag>
)

const emailCaption = ({ children, className = '', Tag = 'p', ...rest }: PropTypes) => (
  <Tag
    className={classNames(
      className,
      'text-12',
    )}
    {...rest}
  >
    {children}
  </Tag>
)

export default {
  XXS,
  XS,
  SM,
  MD,
  LG,
  XL,
  XXL,
  PropertyPage,
  PublicHeader,
  BookingCaption,
  BookingContent,
  emailCaption,
}
