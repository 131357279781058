import { ArrowRightIcon, LoadingIcon } from 'components/icons'
import Button from 'components/home/common/Button'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import classNames from 'classnames'
import Headers from 'components/home/common/typography/Headers'
import CarouselHeaders from 'components/home/common/typography/CarouselHeaders'

export interface CarouselTextContentProps {
  heading?: string
  subheading?: string
  description?: string
  isFetching?: boolean
  button?: {
    href: string
    text: string
  }
  className?: {
    description?: string,
    subheading?: string,
  }
  variant?: 'default' | 'mobile' | 'centered'
}

const CarouselTextContent = ({
  heading,
  subheading,
  description,
  isFetching = false,
  button,
  className,
  variant = 'default',
}: CarouselTextContentProps) => {
  if (!(heading || subheading || description || button || isFetching)) {
    return null
  }

  return (
    <div className={classNames(
      'px-24',
      {
        'md:ml-auto md:mr-20 xl:mr-90': variant === 'default',
      },
    )}>
      <div className="flex flex-col items-start max-w-[500px]">
        {subheading && (
          <CarouselHeaders.H5 className={classNames(
            'mt-16 uppercase mb-14 text-grey-650',
            className?.subheading,
          )}>
            {subheading}
          </CarouselHeaders.H5>
        )}

        {heading && (
          <Headers.BaseTitle Tag="h2" className={classNames(
            'mb-10 whitespace-pre-line text-grey-900',
            'text-26 sm:text-32 md:text-36 xl:text-48',
          )}>
            {heading}
          </Headers.BaseTitle>
        )}

        {description && (
          <Paragraphs.MD className={classNames(
            'mb-25 text-grey-750 leading-22',
            className?.description,
          )}>
            {description}
          </Paragraphs.MD>
        )}

        {
          button && (
            <Button
              href={button?.href}
              variant="secondary"
              className={{
                button: classNames(
                  'hidden text-center items-center gap-10 !text-12',
                  {
                    'lg:flex lg:!text-14': variant === 'default',
                  },
                ),
              }}
            >
              <ArrowRightIcon /> {button?.text}
            </Button>
          )
        }
      </div>

      {isFetching && (
        <div className="h-[355px]">
          <LoadingIcon />
        </div>
      )}
    </div>
  )
}

export default CarouselTextContent
