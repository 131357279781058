import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import LoginSection from './LoginSection'
import { useRouter } from 'next/router'
import RegisterSection from './RegisterSection'
import { useEffect, useState } from 'react'
import LogoLink from 'components/logoLink'
import { AuthSectionPanelProps } from './AuthSectionPanel'

interface AuthSectionProps extends Pick<AuthSectionPanelProps, 'buttonStyle'> {
  showLogo?: boolean
  placeholderPanel?: JSX.Element
  initialSlide?: 'login' | 'register'
}

const AuthSection = ({
  showLogo,
  placeholderPanel,
  initialSlide,
  ...props
}: AuthSectionProps) => {
  const router = useRouter()

  const [selectedIndex, setSelectedIndex] = useState(0)
  useEffect(() => {
    if (initialSlide) {
      setSelectedIndex(headers.findIndex((header) => header.href === `/${initialSlide}`))
    } else {
      setSelectedIndex(headers.findIndex((header) => router.pathname === header.href))
    }
  }, [router.pathname, initialSlide])

  const headers = [
    {
      title: 'Join',
      href: '/register',
    },
    {
      title: 'Sign In',
      href: '/login',
    },
  ]

  return (
    <Tab.Group
      as="div"
      className="flex flex-col flex-grow h-full pt-20"
      selectedIndex={selectedIndex}
      onChange={setSelectedIndex}
    >
      {
        showLogo && (
          <LogoLink
            className="max-w-[127px] mt-10 mb-20 w-full mx-auto"
            variant="no-tag-line"
          />
        )
      }

      <Tab.List className="flex px-12 gap-x-16 mx-25">
        {
          headers.map((header, index) => (
            <Tab
              key={index}
              className="flex w-full text-center focus:outline-none"
            >
              {({ selected }) => (
                <Paragraphs.SM
                  className={classNames(
                    '!leading-22 font-bold tracking-1/2 border-b-2 flex-grow pb-12 pt-16 px-12',
                    {
                      'border-grey-900 text-grey-900': selected,
                      'border-transparent text-grey-300 active:text-grey-400 hover:text-grey-400': !selected,
                    },
                  )}
                  Tag="span"
                >
                  {header.title}
                </Paragraphs.SM>
              )}
            </Tab>
          ))
        }
      </Tab.List>

      <Tab.Panels as="div" className="flex flex-grow pt-16 pb-24 overflow-y-auto px-25">
        <RegisterSection {...props} />

        <LoginSection {...props} placeholderPanel={placeholderPanel} />
      </Tab.Panels>
    </Tab.Group>
  )
}

export default AuthSection
