import { ReactChild } from 'data/types/types'
import React from 'react'
import Headers from 'components/home/common/typography/Headers'
import classNames from 'classnames'
import { useScreenWidth } from 'data/hooks/useScreenWidth'

export interface UserDashboardContentProps {
  title?: {
    regular: string
    italic?: string
    leftElement?: ReactChild
    rightElement?: ReactChild
  }
  sidebar?: ReactChild
  children: ReactChild
  className?: {
    container?: string
    content?: string
  }
}

const UserDashboardContent = ({
  children,
  title,
  sidebar,
  className,
}: UserDashboardContentProps) => {
  const { isMobile } = useScreenWidth(1024)

  const elementCount = [title?.leftElement, title?.rightElement].filter(Boolean).length

  return (
    <div className={classNames(
      'w-full flex px-20 lg:px-0',
      className?.container,
    )}>
      {sidebar}

      <div className={classNames(
        'flex flex-col w-full items-center flex-grow min-w-0',
        className?.content,
      )}>
        {/* main nav */}
        {!isMobile && (
          <div className={classNames(
            'relative flex items-center w-full py-30 px-24',
            'xl:flex-nowrap xl:justify-center',
            'lg:justify-center', {
              'lg:flex-wrap': elementCount === 2,
              'lg:justify-between': elementCount === 1,
            },
          )}>
            <div className={classNames(
              'basis-1/4',
              'lg:basis-1/2',
              'xl:order-none xl:basis-1/4 xl:block',
              {
                'lg:hidden': !title?.leftElement,
                'lg:order-2': elementCount === 2,
                'lg:order-1': elementCount === 1,
              },
            )}>
              {title?.leftElement}
            </div>

            {/* Title */}
            <div className={classNames(
              'flex justify-center w-full gap-2 basis-2/4',
              'lg:order-1',
              'xl:order-none xl:basis-2/4 xl:text-center',
              {
                'pb-14': title?.leftElement && title?.rightElement,
                'basis-full': elementCount === 0,
                'lg:basis-3/4 lg:text-right': elementCount === 1,
                'lg:basis-full': elementCount === 2,
              },
            )}>
              <Headers.UserDashboard>
                {title?.regular}&nbsp;
              </Headers.UserDashboard>

              <Headers.UserDashboard className="italic font-bold">
                {title?.italic}
              </Headers.UserDashboard>
            </div>

            <div className={classNames(
              'basis-1/4 flex justify-end',
              'lg:order-3',
              'xl:order-none xl:basis-1/4 xl:block',
              {
                'lg:basis-1/2': elementCount === 2,
                'hidden': !title?.rightElement,
              },
            )}>
              {title?.rightElement}
            </div>
          </div>
        )}

        {children}
      </div>

    </div>
  )
}

export default UserDashboardContent
