import React, { useState } from 'react'
import placeholderImage from 'assets/images/placeholder.png'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import { Navigation, FreeMode } from 'swiper'
import Image, { StaticImageData } from 'components/Image'
import classNames from 'classnames'
import { useHasLoaded } from 'data/hooks/useHasLoaded'
import FullscreenImageModal from 'components/modals/home/FullscreenImageModal'
import Pill from 'components/home/Pill'
import { useCarouselLoadedImages } from 'data/hooks/useCarouselLoadedImages'
import { ReactChild } from 'data/types/types'
import WishlistModalTrigger from 'components/modals/triggers/WishlistModalTrigger'
import { PublicLayoutVariant } from 'layouts/PublicLayout'

interface CarouselImagesProps {
  images?: {
    url: string | StaticImageData
    alt_text: string
  }[]
  propertyId?: string
  className?: {
    container?: string
    swiper?: string
    slide?: string
    overlay?: string
    image?: {
      container?: string
      image?: string
    }
  }
  height?: number
  showPagination?: boolean
  imageOverlay?: ReactChild
  variant?: PublicLayoutVariant
}

const CarouselImages = ({
  images = [],
  propertyId = undefined,
  className,
  showPagination = true,
  imageOverlay,
  variant = PublicLayoutVariant.Public,
}: CarouselImagesProps) => {
  const { ref: componentRef, hasLoaded } = useHasLoaded()

  return (
    <>
      <div
        ref={componentRef}
        className={classNames(
          className?.container, 'min-h-[24px]',
          {
            'relative': showPagination || imageOverlay,
            'overflow-hidden': className?.container,
          },
        )}
      >
        {
          <>
            {images.length > 1
              ? (
                hasLoaded && (
                  <CarouselSwiper
                    className={className}
                    images={images}
                    propertyId={propertyId}
                  />
                )
              )
              : (
                <div className="relative h-[416px] md:h-[400px] lg:h-[700px] w-full">
                  <Image
                    src={images[0]?.url}
                    alt={images[0]?.alt_text}
                    layout="fill"
                    className="object-cover"
                  />
                </div>
              )
            }

            {
              imageOverlay && (
                <div className="absolute inset-0">
                  {imageOverlay}
                </div>
              )
            }
          </>
        }

        {
          (!imageOverlay && variant === PublicLayoutVariant.Public) && (
            <div className="absolute inset-x-0 bottom-0 bg-white z-[1] h-24 rounded-t-24" />
          )
        }
      </div>
    </>
  )
}

const CarouselSwiper = ({ className, images, propertyId, showPagination = true }: CarouselImagesProps) => {
  const imagesEitherSide = 2
  const [isFullscreen, setIsFullscreen] = useState(false)

  const { onRealIndexChange, isImageLoaded } = useCarouselLoadedImages({
    imagesEitherSide,
    imagesLength: images!.length,
    isLoop: true,
  })

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  const buttonClasses = 'swiper-button absolute visible !w-40 !h-40 sm:!w-50 sm:!h-50 top-1/2 -mt-20 sm:-mt-25 hidden lg:block'

  const breakpoints: SwiperProps['breakpoints'] = {
    768: {
      slidesPerView: 1.3,
    },
    1300: {
      slidesPerView: 'auto',
    },
  }

  return (<>
    <Swiper
      modules={[Navigation, FreeMode]}
      className={classNames('relative', className?.swiper)}
      slidesPerView={1}
      spaceBetween={10}
      centeredSlides={true}
      breakpoints={breakpoints}
      onRealIndexChange={(swiper) => {
        onRealIndexChange(swiper.realIndex)
        setCurrentSlideIndex(swiper.realIndex)
      }}
      navigation={{
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current,
      }}
      onSwiper={(swiper) => {
        setTimeout(() => {
          if (swiper.params.navigation && typeof (swiper.params.navigation) !== 'boolean') {
            // Timeout until prevEl & nextEl refs are defined
            swiper.params.navigation.prevEl = navigationPrevRef.current
            swiper.params.navigation.nextEl = navigationNextRef.current
          }

          // Re-initialise navigation
          swiper.navigation.destroy()
          swiper.navigation.init()
          swiper.navigation.update()
        })
      }}
      loop={true}
      loopedSlides={images!.length * 2}
      observer={true}
    >
      {images != undefined
        ? images.map((image, index) =>
          <SwiperSlide className="rounded-4 relative w-full max-w-[1000px] h-[322px] md:h-[400px] lg:h-[700px]" key={index} onClick={() => setIsFullscreen(true)}>
            <div className="relative w-full h-full">
              <Image
                src={(isImageLoaded(index) && image.url) ? image.url : placeholderImage}
                alt={image.alt_text}
                className="absolute inset-0 object-cover"
                sizes="(max-width: 768px) 100vw,
                    (max-width: 1536px) 80vw,
                    40vw"
                layout="fill"
                priority
              />
            </div>
          </SwiperSlide>,
        )
        : null
      }

      <div ref={navigationPrevRef} className={classNames('swiper-button-prev left-[7px] md:left-20 xl:left-[calc(50vw-600px)]', buttonClasses)} />
      <div ref={navigationNextRef} className={classNames('swiper-button-next right-[7px] md:right-20 xl:right-[calc(50vw-600px)]', buttonClasses)} />

      {
        propertyId
          ? (
            <div className="fixed z-20 lg:flex justify-end top-safe-offset-20 right-24 md:right-120 xl:right-[calc(50vw-500px)] hidden">
              <WishlistModalTrigger propertyId={propertyId.toString()} buttonVariant="bigProperty" />
            </div>
          )
          : null
      }
    </Swiper>

    {
      (showPagination && images!.length > 1) && (
        <div className="absolute z-[2] flex justify-center w-full bottom-36">
          <Pill variant="translucent-dark">
            {currentSlideIndex + 1} of {images!.length}
          </Pill>
        </div>
      )}

    <FullscreenImageModal
      images={images!}
      isOpen={isFullscreen}
      onClose={() => setIsFullscreen(false)}
      startingImageIndex={currentSlideIndex}
      propertyId={propertyId}
    />
  </>)
}

export default CarouselImages
