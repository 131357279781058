import Checkbox from 'components/form/Checkbox'
import ControlledDateInput from 'components/form/Home/ControlledDateInput'
import ControlledNumberInput from 'components/form/Home/ControlledNumberInput'
import ControlledSelect, { Option as SelectOption } from 'components/form/Home/ControlledSelect'
import ControlledSwitch from 'components/form/ControlledSwitch'
import Field from 'components/form/Field'
import File from 'components/form/File'
import ImageUpload from 'components/form/ImageUpload'
import Input from 'components/form/Home/Input'
import Label from 'components/form/Home/Label'
import MultipleImageUpload from 'components/form/Upload/MultipleImageUpload'
import Number from 'components/form/Number'
import Price from 'components/form/Price'
import ProfileImageUpload from 'components/form/ProfileImageUpload'
import ServiceImageUpload from 'components/form/ServiceImageUpload'
import Textarea from 'components/form/Home/Textarea'
import Toggle from 'components/form/Home/Toggle'
import dynamic from 'next/dynamic'

const ControlledCountryCodeSelect = dynamic(() => import('components/form/Home/ControlledCountryCodeSelect'), {
  ssr: false,
})

const ControlledCountrySelect = dynamic(() => import('components/form/Home/ControlledCountrySelect'), {
  ssr: false,
})

const MessageEditor = dynamic(() => import('components/form/Home/MessageEditor'), {
  ssr: false,
})

export default {
  Checkbox,
  ControlledCountryCodeSelect,
  ControlledCountrySelect,
  ControlledDateInput,
  ControlledNumberInput,
  ControlledSelect,
  ControlledSwitch,
  Field,
  File,
  ImageUpload,
  Input,
  Label,
  MultipleImageUpload,
  Number,
  Price,
  ProfileImageUpload,
  ServiceImageUpload,
  Toggle,
  Textarea,
  MessageEditor,
}

export type {
  SelectOption,
}
