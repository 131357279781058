import { config } from 'data/config'
import { useEffect, useState } from 'react'

export const useMobileApp = () => {
  const [isMobileApp, setIsMobileApp] = useState<boolean | null>(null)

  useEffect(() => {
    setIsMobileApp(window.navigator.userAgent === config.mobileAppUserAgent)
  }, [])

  return {
    isMobileApp,
  }
}
