import PublicModalDrawer from 'components/PublicModalDrawer'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import ModalNavControl from 'components/ModalNavControl'
import { InfoCircleIcon, MagazineIcon, MessengerIcon, PhoneCallbackIcon } from 'components/icons'
import CustomerGetInTouchModal from './getHelp/CustomerGetInTouchModal'
import GetHelpModal from './getHelp/GetHelpModal'
import MobileDestinationsModal from 'components/mobile/MobileDestinationsModal'
import { useDispatch } from 'react-redux'
import { setRegisterModalOpen } from 'store/auth'

const NavigationModal = ({ isOpen = false, onClose = () => { }, onLogin = () => { } }) => {
  const dispatch = useDispatch()
  const [getHelpModalOpen, setGetHelpModalOpen] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [openGetInTouchModal, setOpenGetInTouchModal] = useState(false)
  const [destinationsModalOpen, setDestinationsModalOpen] = useState(false)

  const setModal = (type) => {
    setModalType(type)
    setGetHelpModalOpen(true)
  }

  return (
    <PublicModalDrawer isOpen={isOpen} onClose={onClose}>
      <div className="z-30 flex flex-col justify-start lg:h-full lg:justify-between lg:gap-0">
        <div className="antialiased">
          <div className="flex flex-col lg:hidden gap-15">
            <ModalNavControl.Button text="Destinations" className="text-grey-800" onClick={() => setDestinationsModalOpen(true)} />

            <ModalNavControl.Link text="Services" href="/services" className="text-grey-800" />

            <ModalNavControl.Link text="About" href="/about-us" className="text-grey-800" />

            <ModalNavControl.Link text="Membership &amp; Rewards" href="/membership" className="text-grey-800" />
          </div>
          <div className="mt-20 lg:hidden">
            <ModalNavControl.Link text={<>Magazine <MagazineIcon /></>} href="/magazine" className="flex justify-between items-center text-grey-800 border-t border-grey-200 py-10 leading-[40px]" block={true}></ModalNavControl.Link>
          </div>

          <div className="lg:mt-37 text-grey-200">
            <hr />
          </div>
          <div className="flex flex-col mt-30 gap-15">
            <p className="font-medium uppercase text-12 lg:text-14">help & support</p>
            <ModalNavControl.Button text={<>Call Us<InfoCircleIcon className="mr-2" /></>} onClick={() => setOpenGetInTouchModal(true)} className="flex items-center justify-between cursor-pointer text-grey-750" />
            <ModalNavControl.Button text={<>Request a Callback<PhoneCallbackIcon /></>} onClick={() => setModal('callback')} className="flex items-center justify-between cursor-pointer text-grey-750" />
            <ModalNavControl.Button text={<>Send us a Message<MessengerIcon className="mr-2" /></>} onClick={onLogin} className="flex items-center justify-between cursor-pointer text-grey-750" />
          </div>
        </div>
        <Button onClick={onLogin} className="mt-25">
          LOG IN
        </Button>
        <div className="mt-20 text-center text-14 text-grey-500">
          Don&apos;t have an account yet? <span className="underline text-primary" onClick={() => dispatch(setRegisterModalOpen(true))}>Sign up now</span>
        </div>
      </div>

      <CustomerGetInTouchModal
        isOpen={openGetInTouchModal}
        onClose={() => setOpenGetInTouchModal(false)}
      />

      <GetHelpModal
        modalOpen={getHelpModalOpen}
        onClose={() => setGetHelpModalOpen(false)}
        initialType={modalType}
      />

      <MobileDestinationsModal
        isOpen={destinationsModalOpen}
        onClose={() => setDestinationsModalOpen(false)}
      />
    </PublicModalDrawer>
  )
}

NavigationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onLogin: PropTypes.func,
}

export default NavigationModal
