import React from 'react'
import Button from 'components/home/common/Button'
import PublicMobileModal from 'components/PublicMobileModal'
import Headers from 'components/home/common/typography/Headers'
import classNames from 'classnames'

interface ConfirmPublicModalProps {
  isOpen: boolean
  onClose?: () => void
  title?: string
  message: string
  action?: () => void
}

const ConfirmPublicModal = ({
  isOpen,
  onClose,
  message,
  action,
  title,
}: ConfirmPublicModalProps) => {
  const handleAction = () => {
    action?.()
    onClose?.()
  }

  return (
    <PublicMobileModal
      isOpen={isOpen}
      onClose={() => onClose?.()}
      className={{
        panel: classNames('flex flex-col px-24 pb-30 max-w-lg', !title && 'pt-60'),
      }}
    >
      { title && <Headers.H1 className="pt-16 font-serif font-bold text-center mb-25">{title}</Headers.H1>}

      <p className="text-center mb-50 text-grey-800">{message}</p>
      <div className="flex justify-between gap-10">
        <Button
          onClick={onClose}
          variant="secondary"
          style="modal"
          block
        >
          Cancel
        </Button>

        <Button
          onClick={handleAction}
          block
          style="modal"
          variant="primary"
        >
          Confirm
        </Button>
      </div>
    </PublicMobileModal>
  )
}

export default ConfirmPublicModal
