const getNumberArray = (length) => {
  return Array(length).fill(null).map((_, i) => i)
}

const getLoadedImagesArray = (
  swiperIndex,
  imagesEitherSide,
  imagesLength,
  numberArray,
  loadedImages,
) => {
  let lowNumber = swiperIndex - imagesEitherSide >= 0
    ? swiperIndex - imagesEitherSide
    : 0
  let highNumber = swiperIndex + imagesEitherSide <= imagesLength
    ? swiperIndex + imagesEitherSide
    : imagesLength - 1

  return [...new Set([...loadedImages, ...numberArray.slice(lowNumber, highNumber + 1)])]
}

export {
  getNumberArray,
  getLoadedImagesArray,
}
