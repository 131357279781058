import { Dialog } from '@headlessui/react'
import Image, { StaticImageData } from 'components/Image'
import { CrossIcon, RotateIcon } from 'components/icons'
import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import WishlistModalTrigger from '../triggers/WishlistModalTrigger'
import useOrientation, { BasicOrientation } from 'data/hooks/useOrientation'
import classNames from 'classnames'
import Pill from 'components/home/Pill'

interface FullscreenImageModalProps {
  isOpen: boolean
  onClose: () => void
  images: {
    url: string | StaticImageData
    alt_text?: string
  }[]
  startingImageIndex?: number
  propertyId?: string
}

const FullscreenImageModal = ({
  isOpen,
  onClose,
  images,
  propertyId,
  startingImageIndex = 0,
}: FullscreenImageModalProps) => {

  const [currentSlide, setCurrentSlide] = useState(startingImageIndex)
  const [isManuallyRotated, setIsManuallyRotated] = useState(false)

  const { orientation } = useOrientation()

  // Reset rotation on orientation change, this exists
  // because some users may not know how to rotate back
  useEffect(() => {
    setIsManuallyRotated(false)
  }, [orientation])

  const defaultGridItemClassName = 'flex pointer-events-auto'

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Dialog.Panel className="fixed top-0 left-0 z-10 w-screen h-screen bg-white">

        <div className="absolute z-20 grid w-screen h-screen grid-cols-3 grid-rows-3 pointer-events-none">

          {/* Rotate button */}
          <div className={classNames(defaultGridItemClassName, {
            'row-start-3 col-start-2 mb-18 justify-center items-end': !isManuallyRotated,
            'row-start-2 col-start-1 justify-start items-center': isManuallyRotated,
          })}>
            <Pill
              variant="translucent-dark"
              onClick={() => setIsManuallyRotated(!isManuallyRotated)}
              className={classNames('!font-poppins !font-medium pointer-events-auto', {
                'rotate-90': isManuallyRotated,
              })}
            >
              <RotateIcon className="w-12 h-12" />
              <span>Rotate</span>
            </Pill>
          </div>

          {/* Wishlist Button */}
          {propertyId && <div className={classNames(defaultGridItemClassName, {
            'row-start-1 col-start-3 justify-end p-24': !isManuallyRotated && orientation === BasicOrientation.Portrait,
            'row-start-3 col-start-1 justify-start items-end px-[28px] py-20': isManuallyRotated && orientation === BasicOrientation.Portrait,
            'row-start-3 col-start-3 justify-end items-end px-[28px] py-20': orientation === BasicOrientation.Landscape,
          })}>
            <WishlistModalTrigger
              propertyId={propertyId}
              buttonVariant="whiteHeart"
              className={isManuallyRotated ? 'rotate-90' : ''}
            />
          </div>}

          {/* Close Button */}
          <div className={classNames(defaultGridItemClassName, {
            'row-start-1 col-start-1 justify-start items-start p-24': !isManuallyRotated && orientation === BasicOrientation.Portrait,
            'row-start-3 col-start-3 justify-end items-end px-[28px] py-20': isManuallyRotated && orientation === BasicOrientation.Portrait,
            'row-start-1 col-start-3 justify-end items-start px-[28px] py-20': orientation === BasicOrientation.Landscape,
          })}>
            <button onClick={onClose} type="button" className="flex items-center justify-center h-[34px] w-[34px] rounded-4 focus:ring-2 focus:ring-primary-fresh">
              <CrossIcon className="text-white text-24 hover:text-grey-300" />
            </button>
          </div>

          {/* Progress */}
          <div className={classNames(defaultGridItemClassName, {
            'row-start-1 col-start-2 justify-center items-start': !isManuallyRotated,
            'row-start-2 col-start-3 justify-end items-center': isManuallyRotated,
            'mt-16': orientation === BasicOrientation.Landscape,
            'mt-24': orientation === BasicOrientation.Portrait,
          })}>
            <Pill variant="translucent-dark" className={isManuallyRotated ? 'rotate-90' : ''}>
              {currentSlide} of {images.length}
            </Pill>
          </div>

        </div>

        <Swiper
          initialSlide={startingImageIndex}
          className="h-full bg-grey-900"
          loop={true}
          direction={isManuallyRotated ? 'vertical' : 'horizontal'}
          onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex + 1)}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index} className="h-full">
              <div className="flex items-center justify-center w-full h-full">
                <div className={classNames('relative w-full h-full', {
                  'rotate-90': isManuallyRotated && orientation === BasicOrientation.Portrait,
                  '-rotate-90': isManuallyRotated && orientation === BasicOrientation.Landscape,
                })}>
                  <Image
                    src={image.url}
                    alt={image.alt_text}
                    layout="fill"
                    className="object-contain"
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

      </Dialog.Panel>
    </Dialog>
  )
}

export default FullscreenImageModal
