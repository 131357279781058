import dynamic from 'next/dynamic'

import Checkbox from 'components/form/Checkbox'
import Field from 'components/form/Field'
import Label from 'components/form/Label'
import SubLabel from 'components/form/SubLabel'
import Input from 'components/form/Input'
import Price from 'components/form/Price'
import Switch from 'components/form/Switch'
import ControlledSwitch from 'components/form/ControlledSwitch'
import Select from 'components/form/Select'
import Option from 'components/form/Option'
import File from 'components/form/File'
import Number from 'components/form/Number'
import ImageUpload from 'components/form/ImageUpload'
import MultipleImageUpload from 'components/form/Upload/MultipleImageUpload'
import Textarea from 'components/form/Textarea'
import CountryCodeSelect from 'components/form/countryCodeSelect'
import ProfileImageUpload from 'components/form/ProfileImageUpload'
import ServiceImageUpload from 'components/form/ServiceImageUpload'

const HtmlEditor = dynamic(
  () => import('components/form/HtmlEditor'),
  {
    ssr: false,
  },
)

const MessageEditor = dynamic(
  () => import('components/form/MessageEditor'),
  {
    ssr: false,
  },
)

export default {
  Checkbox,
  CountryCodeSelect,
  Field,
  Label,
  SubLabel,
  Input,
  Price,
  Switch,
  ControlledSwitch,
  Select,
  Option,
  File,
  Number,
  ImageUpload,
  MultipleImageUpload,
  Textarea,
  ProfileImageUpload,
  HtmlEditor,
  MessageEditor,
  ServiceImageUpload,
}
