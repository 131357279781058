import * as zip from '@zip.js/zip.js'

function classNames (...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const stripHTML = (string: string) => {
  return (string.replace(/&nbsp;/g, '')).replace(/(<([^>]+)>)/gi, '')
}

const unescapeHtml = (string: string) => {
  let html = string
  html = html.replace(/&lt;/g, '<')
  html = html.replace(/&gt;/g, '>')
  html = html.replace(/&quot;/g, '"')
  html = html.replace(/&#39;/g, '\'')
  html = html.replace(/&amp;/g, '&')
  return html
}

const downloadUrl = (url: string, filename: string) => {
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', filename)
  a.setAttribute('target', '_blank')
  a.style.display = 'none'

  document.body.appendChild(a)
  a.click()
  a.parentNode?.removeChild(a)
}

const downloadZip = (
  zipName: string,
  items: {
    url: string
    blob: Blob
    filename: string
  }[],
) => {
  const zipWriter = new zip.ZipWriter(new zip.BlobWriter('application/zip'))

  const existingFiles: { name: string, count: number }[] = []

  items
    .forEach((item) => {
      let filename = item.filename

      const existingFile = existingFiles.find((file) => file.name === filename)
      if (existingFile !== undefined) {
        const [file, extension] = filename.split('.')
        filename = `${file}-${existingFile.count}.${extension}`

        existingFile.count++
      } else {
        existingFiles.push({ name: filename, count: 1 })
      }

      zipWriter.add(
        filename,
        new zip.BlobReader(item.blob),
      )
    })

  zipWriter.close().then((blob) => {
    downloadUrl(
      URL.createObjectURL(blob),
      `${zipName}.zip`,
    )
  })
}

const convertUrlToBlobUrl = async (url: string) => {
  return URL.createObjectURL(await getBlobFromUrl(url))
}

const getBlobFromUrl = async (url: string) => {
  const response = await fetch(url, { cache: 'reload' })
  return await response.blob()
}

export {
  classNames,
  stripHTML,
  unescapeHtml,
  downloadUrl,
  downloadZip,
  convertUrlToBlobUrl,
  getBlobFromUrl,
}
