import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import Number from 'components/form/Number'
import { Transition } from '@headlessui/react'
import { useDispatch } from 'react-redux'
import { setAdults, setChildren } from 'store/booking-request'

const GuestsPopover = ({
  setNumBedrooms,
  setNumAdults,
  setNumChildren,
  numAdults = 0,
  numChildren = 0,
  numBedrooms = 0,
  open = false,
  setIsOpen,
  children,
  confirmText = 'confirm guests',
  variant = 'local',
  position = '',
  isProperty = false,
  borderSize = 'md',
}) => {
  const dispatch = useDispatch()

  const onFinish = () => {
    setIsOpen(false)
  }

  const adultCrement = (numberOfAdults) => {
    if (numberOfAdults >= 0) {
      if (variant == 'global') {
        dispatch(setAdults(numberOfAdults ? parseInt(numberOfAdults) : 0))
      } else {
        setNumAdults(numberOfAdults)
      }
    }
  }

  const childCrement = (numberOfChildren) => {
    if (numberOfChildren >= 0) {
      if (variant == 'global') {
        dispatch(setChildren(numberOfChildren ? parseInt(numberOfChildren) : 0))
      } else {
        setNumChildren(numberOfChildren)
      }
    }
  }

  return (
    <>
      {open && <div className="fixed inset-0" onClick={() => setIsOpen(false)}></div>}
      <div className={`relative w-full ${isProperty ? 'max-w-[270px]' : 'lg:max-w-[270px]'} h-[62px] lg:h-[80px] lg:px-0 lg:py-0 `}>
        <div className={`border-grey-150 border ${borderSize}:border-none w-full h-full`}>
          {children}
        </div>
        <Transition
          show={open}
          as={React.Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <div className={` overlay bg-white shadow-public-searchbar ${position}`}>
            {
              !isProperty
              && <div className="grid grid-cols-2 text-grey-800 antialiased border-b border-grey-250 border-opacity-30 bg-white px-30 pt-[31px] pb-[23px]">
                <p>Bedrooms</p>
                <Number
                  placeholder="0"
                  min={0}
                  value={numBedrooms}
                  onChange={(numBedrooms) => setNumBedrooms(numBedrooms === 0 ? 0 : numBedrooms)}
                  variant="modal"
                />
              </div>
            }
            <div className="grid grid-cols-2 pt-[27px] bg-white px-30 text-grey-800 antialiased">
              <div className="flex flex-col gap-30">
                <p>Adults</p>
                <p>Children</p>
              </div>
              <div className="flex flex-col gap-30">
                <Number
                  placeholder="0"
                  min={0}
                  value={numAdults}
                  onChange={adults => adultCrement(adults)}
                  variant="modal"
                />
                <Number
                  placeholder="0"
                  min={0}
                  value={numChildren}
                  onChange={children => childCrement(children)}
                  variant="modal"
                />
              </div>
            </div>
            <div className="flex justify-center py-30">
              <Button
                onClick={onFinish}
                type="submit"
                className="rounded-full whitespace-nowrap text-12 h-[34px]">
                {confirmText}
              </Button>
            </div>

          </div>
        </Transition>
      </div >
    </>
  )
}

GuestsPopover.propTypes = {
  numBedrooms: PropTypes.number,
  setNumBedrooms: PropTypes.func,
  numAdults: PropTypes.number,
  setNumAdults: PropTypes.func,
  numChildren: PropTypes.number,
  setNumChildren: PropTypes.func,
  children: PropTypes.node,
  setIsOpen: PropTypes.func,
  open: PropTypes.bool,
  variant: PropTypes.string,
  confirmText: PropTypes.string,
  position: PropTypes.string,
  isProperty: PropTypes.bool,
  borderSize: PropTypes.string,
}

export default GuestsPopover
