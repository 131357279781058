import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CrossIcon } from './icons'
import classNames from 'classnames'
import LogoLink from './logoLink'
import { ReactChild } from 'data/types/types'

type PropTypes = {
  isOpen: boolean,
  onClose: () => void,
  children: ReactChild,
  className?: string,
  position: 'left' | 'right' | 'left-right-icon',
  iconClassName?: string,
  showHeader?: boolean,
  zIndex?: string,
  maxWidth?: string,
  icon?: ReactChild,
}

const PublicModalDrawer = ({
  className = 'pt-20 lg:pt-90',
  isOpen = false,
  position = 'right',
  onClose = () => { },
  children,
  iconClassName,
  maxWidth = 'max-w-[480px]',
  showHeader = true,
  zIndex = 'z-50',
  icon = <CrossIcon />,
}: PropTypes) => {
  const positionClass = classNames({
    'left-0': position === 'left' || position === 'left-right-icon',
    'right-0': position === 'right',
  })

  const iconClass = classNames({
    'left-20': position === 'left',
    'right-20': position === 'right' || position === 'left-right-icon',
  })

  return (
    <Transition appear show={isOpen} as={Fragment as any}>
      <Dialog
        className={classNames('fixed inset-0', zIndex)}
        onClose={onClose}
      >
        <div className="relative w-full h-full">
          <Transition.Child
            as={Fragment as any}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-10" />
          </Transition.Child>

          <Transition.Child
            as={Fragment as any}
            enter="ease-out duration-200"
            enterFrom={position === 'right' ? 'translate-x-[100%]' : 'translate-x-[-100%]'}
            enterTo="translate-x-0"
            leave="ease-in duration-200"
            leaveFrom="translate-x-0"
            leaveTo={position === 'right' ? 'translate-x-[100%]' : 'translate-x-[-100%]'}>
            <div
              className={classNames(
                'absolute w-full text-left align-middle transition-all transform bg-white shadow-modal h-full',
                positionClass,
                maxWidth,
              )}>
              <div className={classNames('h-full overflow-auto p-20 lg:px-55', className)}>
                {showHeader && <LogoLink className="block mx-auto w-full max-w-[120px] mt-15 mb-30 lg:hidden focus:outline-none"/>}
                {children}
              </div>
              <button
                className={classNames('absolute top-20 stroke-2 cursor-pointer focus:outline-none focus:text-primary', iconClass, iconClassName)}
                onClick={onClose}>
                {icon}
              </button>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default PublicModalDrawer
