import classNames from 'classnames'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import { ReactChild } from 'data/types/types'
import React from 'react'

interface GuestDisclaimerProps {
  children: ReactChild
  className?: string
  onClick?: () => void
}

const GuestDisclaimer = ({
  children,
  className,
  onClick,
}: GuestDisclaimerProps) => {

  return (
    <button
      onClick={onClick}
      type="button"
      className="bg-grey-100 rounded-10 mb-30"
      disabled={!onClick}
    >
      <Paragraphs.MD className={classNames(
        'leading-22 tracking-1/4 text-grey-800',
        className,
      )}>
        {children}
      </Paragraphs.MD>
    </button>
  )
}

export default GuestDisclaimer
