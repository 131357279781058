import CarouselImages from 'components/carousels/CarouselImages'
import { ReactChild } from 'data/types/types'
import MobilePublicStickyBanner, { MobileStickyBannerProps } from 'layouts/MobilePublicStickyBanner'
import dynamic from 'next/dynamic'
import { StaticImageData } from 'components/Image'
import { PublicLayoutVariant } from 'layouts/PublicLayout'

const CloudflareStream = dynamic(() => import('components/CloudflareStream'), {
  ssr: false,
})

export interface MobilePublicNavbarProps extends MobileStickyBannerProps {
  visible?: boolean
  useVideo?: boolean
  images?: {
    url: string | StaticImageData
    alt_text: string
  }[]
  imageOverlay?: ReactChild
  className?: {
    container?: string,
    child?: string,
    image?: {
      carousel?: string
      overlay?: string
    },
  }
  variant?: PublicLayoutVariant
}

const MobilePublicNavbar = ({
  images,
  visible = false,
  useVideo = false,
  imageOverlay,
  variant,
  ...props
}: MobilePublicNavbarProps) => {
  return (
    <>
      { visible && (
        <MobilePublicStickyBanner
          {...props}
        />
      )}

      { useVideo
        ? (
          <div className="w-full overflow-hidden">
            <CloudflareStream video />
          </div>
        )
        : (images && (
          <CarouselImages
            variant={variant}
            className={{
              container: props.className?.image?.carousel,
              overlay: props.className?.image?.overlay,
            }}
            images={images}
            propertyId={props?.wishlist?.id}
            imageOverlay={imageOverlay}
          />
        ))}
    </>
  )
}

export default MobilePublicNavbar
