import classNames from 'classnames'
import Image from 'components/Image'
import UserTripServicePill from 'components/booking/trip-schedule/UserTripServicePill'
import { ClockOutlineIcon } from 'components/icons'
import { TripScheduleStatus, isDraft, isPending } from 'data/enums/trip-schedule/trip-schedule-status'
import { getFormattedTime } from 'data/helpers/survey/tripSchedule'
import { isCheckInOrOut } from 'data/helpers/trip-schedule'
import { getTripScheduleServiceStatusType } from 'data/helpers/trip-schedule-group'
import { RestBooking } from 'data/types/booking'
import { TripScheduleService } from 'data/types/bookings/trip_schedule'

interface CalendarViewItemProps {
  service: TripScheduleService
  action?: () => void
  booking: RestBooking
}

const CalendarViewItem = ({
  service,
  action,
  booking,
}: CalendarViewItemProps) => {

  const cardClasses = classNames(
    'p-[13px] rounded-16 bg-grey-150 flex flex-col gap-[7px] border-r border-grey-200',
    {
      'bg-red-100': !isCheckInOrOut(service?.title) && isPending(service?.status),
      'bg-grey-150 text-grey-650': isDraft(service?.status),
      'opacity-50': service.status === TripScheduleStatus.CANCELLED,
    },
  )

  return (
    <button
      onClick={action}
      className={cardClasses}
      type="button"
    >

      {/* Time */}
      <div className="flex justify-between w-full">
        <div className="flex items-center gap-[6px] text-grey-700">
          <ClockOutlineIcon className="h-[13px] w-[13px] stroke-[1.5]" />
          <p className="text-13 leading-22 tracking-1/4">
            {getFormattedTime(service?.start_time, service?.end_time)}
          </p>
        </div>

        <div className="flex flex-col md:items-end gap-y-5">
          <UserTripServicePill
            service={{
              title: service?.title,
              status: getTripScheduleServiceStatusType(service?.status),
            }}
            serviceRequestId={service.group.service_request_id}
            booking={{ id: booking?.id.toString() }}
            bookingInformation={booking?.has_booking_information}
            type="calendar"
          />
        </div>
      </div>

      <div className="flex items-center gap-8">
        <div className="relative rounded-full h-36 min-w-[36px] overflow-clip">
          {service?.image
            ? <Image
              src={service?.image}
              alt={service?.title}
              className="object-cover"
              layout="fill"
            />
            : <div className={classNames(
              'w-full h-full bg-primary-fresh rounded',
              {
                'bg-opacity-50': service?.status !== TripScheduleStatus.CONFIRMED,
              },
            )} />
          }
        </div>

        <div className="flex flex-col items-start w-full h-full text-12 lg:text-14 leading-22">
          {/* Service Name */}
          <p className="font-semibold font-poppins text-13 leading-22 text-grey-900 tracking-1/4">
            {service?.title}
          </p>

          {service?.subtitle && (
            <p className="text-grey-800 text-13 leading-22">
              {service?.subtitle}
            </p>
          )}
        </div>
      </div>
    </button>
  )
}

export default CalendarViewItem
