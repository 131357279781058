import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { FieldError } from 'react-hook-form'
import Label from '../Label'
import Error from '../Error'

interface InputPropTypes extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'dangerouslySetInnerHTML'|'children'|'value'|'type'> {
  className?: string
  label?: string
  prefixIcon?: JSX.Element
  suffixIcon?: JSX.Element
  capitalise?: boolean
  error?: FieldError
  variant?: 'thin' | 'thick'
}

// The correct way to do with Excluding HTMLInputTypeAttribute does not work as there is a `| (string & {})` type which breaks the exclusion
// This is here to ensure that the type is not `time` and `value` is not undefined
type HTMLInputTypes = 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week'
type InputTypeAndValueProps = {
  type: 'time'
  value: string | ReadonlyArray<string> | number
} | {
  type?: Exclude<HTMLInputTypes, 'time'>
  value?: React.InputHTMLAttributes<HTMLInputElement>['value']
}

const Input = forwardRef<HTMLInputElement, InputPropTypes & InputTypeAndValueProps>(function Input ({
  className,
  label,
  prefixIcon,
  suffixIcon,
  capitalise = false,
  error,
  variant = 'thick',
  ...props
}, ref) {

  return (
    <div className="flex flex-col w-full">
      {
        label && (
          <Label variant="survey">{label}</Label>
        )
      }

      <div className={classNames(
        `${className} w-full text-16 ring-1 focus:ring-opacity-50 rounded-4 flex items-center relative`,
        {
          'ring-red ring-opacity-50': error,
          'ring-input-border ring-opacity-100': !error,
        },
      )}>
        {
          prefixIcon
            ? <div className="absolute top-0 bottom-0 flex items-center left-21">
              {prefixIcon}
            </div>
            : null
        }

        <input
          ref={ref}
          className={classNames(
            'text-grey-800 placeholder-grey-500 text-16 leading-20 w-full border-none bg-transparent focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-50 rounded-4',
            {
              'px-20 py-10': variant === 'thin',
              'p-20': variant === 'thick',
              'pr-50': suffixIcon,
              'pl-50': prefixIcon,
              'capitalize': capitalise,
            },
          )}
          style={
            props.type === 'time' && props.value === undefined
              ? { WebkitTextFillColor: '#A1A1A1' }
              : props.style
          }
          {...props}
        />

        {
          suffixIcon
            ? <div className="absolute top-0 bottom-0 flex items-center right-21 text-grey-800">
              {suffixIcon}
            </div>
            : null
        }
      </div>

      <Error className="mt-10" error={error?.message} />
    </div>
  )
})

export default Input
