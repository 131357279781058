import React from 'react'
import ReadMoreButton from 'components/ReadMoreButton'
import { ArrowRightIcon } from 'components/icons'

const ServiceRequestNextButton = ({
  isLoading,
  disabled,
}: {
  isLoading?: boolean,
  disabled?: boolean,
}) => {
  return (
    <ReadMoreButton
      variant="primary"
      icon={
        <ArrowRightIcon className="mt-2 text-18" />
      }
      className="max-w-[150px] text-16 justify-center !gap-12 md:ml-40"
      buttonType="submit"
      isLoading={isLoading}
      disabled={disabled || isLoading}
    >
      Next
    </ReadMoreButton>
  )
}

export default ServiceRequestNextButton
