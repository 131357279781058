enum notificationType {
  INQUIRY_CALLBACK = 'App\\Notifications\\Admin\\CallbackRequest',
  INQUIRY_GENERAL = 'App\\Notifications\\Admin\\GeneralInquiry',
  INQUIRY_PROPERTY = 'App\\Notifications\\Admin\\PropertyInquiry',
  INQUIRY_AGENT_ASSIGNED = 'App\\Notifications\\Admin\\InquiryAssignedBookingAgent',
  SERVICE_REQUEST_USER = 'App\\Notifications\\User\\ServiceRequestToUser',
  SURVEY_BANK_INFO_ADMIN = 'App\\Notifications\\Admin\\SurveyBankInfo',
  SURVEY_BANK_INFO_USER = 'App\\Notifications\\User\\SurveyBankInfoToUser',
  SERVICE_REQUEST_ADMIN = 'App\\Notifications\\Admin\\ServiceRequest',
  TIP_SENT_TO_CONCIERGE = 'App\\Notifications\\Admin\\TipSentToConcierge',
  PAYMENT_RECEIVED_USER = 'App\\Notifications\\User\\PaymentReceived',
}

export { notificationType }
