import React from 'react'
import PropTypes from 'prop-types'

const Notification = ({ title, message, type }) => {
  let backgroundColor
  switch (type) {
    case 'success':
      backgroundColor = 'bg-green'
      break
    case 'error':
      backgroundColor = 'bg-red'
      break
  }

  return (
    <div className={`max-w-150 ${backgroundColor} shadow px-15 py-10 rounded-10 text-white`}>
      {
        title
          ? <p className="font-bold">{title}</p>
          : null
      }

      <p>{message}</p>
    </div>
  )
}

Notification.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error']).isRequired,
}

export default Notification
