import React, { useState } from 'react'
import Accordion from 'components/accordion'
import { MessengerIcon, PhoneIcon } from 'components/icons'
import CustomerGetInTouchModal from 'components/modals/getHelp/CustomerGetInTouchModal'
import { config } from 'data/config'

const HowCanWeHelpYouSection = () => {
  const [getHelpModalOpen, setGetHelpModalOpen] = useState(false)

  return (
    <div className="py-40 border-t sm:bg-grey-100 border-grey-750 border-opacity-20 md:border-none sm:py-80">
      <div className="flex flex-col justify-between container-public sm:flex-row">

        <div className="justify-between w-full">
          <div>
            <div className="font-serif font-bold text-32 lg:text-48 leading-title tracking-title text-grey-1000">
              <p>Want to <br /> get in touch?</p>
            </div>
            <div className="mb-40 font-normal opacity-50 text-18 mt-18 text-grey-1000 sm:opacity-100 sm:text-grey-800">
              <p>We are just a call or message away.</p>
            </div>
          </div>

          <div className="w-full mt-40 sm:mt-80">
            <div className="cursor-pointer px-18 py-8 sm:p-0 flex items-center w-full rounded-4 border gap-25 border-[#DFDFE2] sm:border-opacity-0">
              <div className="flex items-center justify-center w-40 h-40 rounded-full text-20 sm:bg-grey-750 sm:bg-opacity-20">
                <PhoneIcon />
              </div>
              <a
                id="call-ama-selections"
                className="hidden border-b border-grey-1000 text-18 sm:block"
                href={`tel:${config.amaSelectionsPhoneNumber}`}
              >
                {config.amaSelectionsDisplayPhoneNumber}
              </a>
              <a
                id="call-ama-selections"
                className="text-18 sm:hidden"
                href={`tel:${config.amaSelectionsPhoneNumber}`}
              >
                Give us a call
              </a>
            </div>
            <button type="button" className="flex items-center px-18 py-8 sm:p-0 mt-10 sm:mt-20 gap-25 w-full rounded-4 border border-[#DFDFE2] sm:border-opacity-0" onClick={() => setGetHelpModalOpen(true)} >
              <div className="flex items-center justify-center w-40 h-40 rounded-full text-20 sm:bg-grey-750 sm:bg-opacity-20">
                <MessengerIcon />
              </div>
              <p className="text-18">Send us a Message</p>
            </button>
          </div>
        </div>

        <div className="w-full mt-60 sm:mt-0">
          <Accordion>
            <Accordion.Item style={{ items: 'pl-0 md:pl-10' }} title="What makes A.M.A Selections unique?">
              <div className="text-grey-800 opacity-90 text-14">
                <p>We are a technology-driven hospitality company. We offer a selection of the top 1% of verified homes in the World’s most fashionable destinations complemented with an innovative combination of hands-on service and proprietary tech.</p>
                <br />
                <p>We have reimagined a booking experience tailored to the luxury traveler. Our industry-leading high-tech approach allows you to seamlessly request services, recommendations, share your trip itinerary and more, directly from your account.</p>
                <br />
                <p>We rigorously vet the very best property owners and local home management companies that adhere to our strict quality standards. This provides you with reliable and professional on-site assistance throughout your stay.</p>
              </div>
            </Accordion.Item>
            <Accordion.Item style={{ items: 'pl-0 md:pl-10' }} title="What is the dedicated concierge service? Is this included in my booking?">
              <div className="text-grey-800 opacity-90 text-14">
                <p>Upon booking, you will be introduced to your dedicated concierge, a member of our team who will assist you in tailoring your holiday with bespoke services and experiences.</p>
                <br />
                <p>Our in-house dedicated concierge team works during regular office hours. All services must be organized prior to your arrival. Your trip itinerary will be shared with our trusted, local home management company who will be your point of contact once you’ve arrived at the property.</p>
                <br />
                <p>The pre-arrival dedicated concierge feature is included in your booking however, a service fee may apply to services booked.</p>
              </div>
            </Accordion.Item>
            <Accordion.Item style={{ items: 'pl-0 md:pl-10' }} title="How far in advance should I request services?">
              <div className="text-grey-800 opacity-90 text-14">
                <p>The earlier the better! In general, we advise our guests to request and confirm additional services between 3 to 6 months prior to arrival.</p>
                <br />
                <p>This is to ensure that our service providers still have availability for your request.</p>
              </div>
            </Accordion.Item>
            <Accordion.Item style={{ items: 'pl-0 md:pl-10' }} title="Will someone welcome me at the property? Will there be a local point of contact to assist me with any issues during my stay?">
              <div className="text-grey-800 opacity-90 text-14">
                <p>All of our guests will enjoy an in-person greeting upon arrival as well as a thoughtfully curated welcome basket.</p>
                <br />
                <p>Depending on the home you book, your local point of contact will either be the staff at the property or a member of the trusted, local home management company. They will be able to assist you with any questions, issues or emergencies throughout your stay.</p>
              </div>
            </Accordion.Item>
            <Accordion.Item style={{ items: 'pl-0 md:pl-10' }} title="What is required to confirm a booking? What are the payment terms and will I need to pay a refundable damage deposit?">
              <div className="text-grey-800 opacity-90 text-14">
                <p>In order to confirm a booking, you will need to sign our booking contract, send us a copy of your passport and process payment via bank transfer or credit card.</p>
                <br />
                <p>We require a 50% deposit and, depending on the property, the balance of rental will be due either 60 or 90 days prior to arrival. All of our properties require a refundable damage deposit to be paid along with the balance of rental.</p>
              </div>
            </Accordion.Item>
          </Accordion>
        </div>

      </div>

      <CustomerGetInTouchModal
        isOpen={getHelpModalOpen}
        onClose={() => setGetHelpModalOpen(false)}
      />
    </div>
  )
}

export default HowCanWeHelpYouSection
