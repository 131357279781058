import { parseDate, validateDate } from '@ama-selections/ui'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const toUTC = (date) => {
  return validateDate(date) ? parseDate(date).utc(true) : null
}

export {
  toUTC,
}
