import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DayPicker from 'react-day-picker'
import { formatShort, getDisabledSingularDates, validateDate, dateWithoutTimezone } from 'data/helpers/dates'
import dayjs from 'dayjs'
import { CalendarSearchBarIcon } from 'components/icons'
import Button from 'components/Button'
import { Transition } from '@headlessui/react'
import { toUTC } from 'data/helpers/timezone'

const ArrivalDeparturePopover = ({
  setIsOpen,
  isOpen = false,
  arrival,
  setArrival,
  departure,
  setDeparture,
  disabledDays = [],
  position = 'mx-auto overflow-auto sm:w-max overlay',
  className,
  borderClassName,
}) => {
  const [invalidDates, setInvalidDates] = useState([])

  const parseDate = (value) => {
    return value ? dateWithoutTimezone(toUTC(dayjs(value)).toDate()) : null
  }

  const formatDate = (date) => {
    return formatShort(toUTC(date))
  }

  const [modifiers, setModifiers] = useState({ from: parseDate(arrival), to: parseDate(departure) })

  const handleDayClick = (date, modifiers = {}) => {
    if (modifiers.disabled) {
      return
    }

    if (arrival) {
      setDeparture(date)
    } else {
      setArrival(date)
    }
  }

  useEffect(() => {
    setModifiers({
      from: arrival ? parseDate(arrival) : null,
      to: departure ? parseDate(departure) : null,
    })

    if (arrival) {
      let soonestAvailableDate = null

      disabledDays.map((disabledDate) => {
        if (arrival < disabledDate.after && disabledDate.after < soonestAvailableDate) {
          soonestAvailableDate = disabledDate.after
        } else if (!soonestAvailableDate && disabledDate.after > arrival) {
          soonestAvailableDate = disabledDate.after
        }
      })

      setInvalidDates([{ after: soonestAvailableDate }, { before: arrival }])
    }
  }, [arrival, departure])

  const confirmDates = () => {
    setArrival(modifiers.from ? modifiers.from : null)
    setDeparture(modifiers.to ? modifiers.to : null)
    setIsOpen(false)
  }

  const clearDates = () => {
    setModifiers({ from: null, to: null })
    setArrival(null)
    setDeparture(null)
    setInvalidDates([])
  }

  return (
    <>
      {isOpen && <div className="fixed inset-0" onClick={() => setIsOpen(false)} />}
      <div className={`lg:relative w-full h-[62px] lg:h-full bg-white px-[30px] lg:px-0 lg:py-0 ${className ? className : 'border border-grey-150 md:border-none'}`}>
        <div className="flex items-center justify-center w-full h-full">
          <button type="button" className="flex items-center justify-between w-full gap-10 cursor-pointer lg:w-min lg:justify-center text-grey-500 whitespace-nowrap" onClick={() => setIsOpen(!isOpen)}>
            <div className="flex w-full max-w-[200px] items-center ">
              <div className="mr-15 text-20 text-primary"><CalendarSearchBarIcon /></div>
              <div className={`${arrival ? 'text-grey-800' : 'text-grey-500'}`}>{arrival ? formatDate(arrival) : 'Arrival'}</div>
            </div>
            <div className={`hidden lg:block ${arrival || departure ? 'text-grey-800' : 'text-grey-500'}`}>-</div>
            <div className={`block lg:hidden ${arrival || departure ? 'text-grey-800' : 'text-grey-500'}`}>|</div>
            <div className={`max-w-[200px] w-full justify-end flex ${departure ? 'text-grey-800' : 'text-grey-500'}`}>{departure ? formatDate(departure) : 'Departure'}</div>
          </button>
        </div>

        <Transition
          show={isOpen}
          className="transition duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={`lg:h-[3px] w-full bg-primary ${borderClassName}`} />
          <div className={`shadow-public-searchbar inset-x-0 lg:h-[inherit] bg-white lg:max-w-[1000px] ${position}`}>
            <div className="pt-40 mx-auto pb-30 sm:px-30 h-min">
              <DayPicker
                className="flex justify-center focus DayPickerPublic DayPicker-custom-inline"
                numberOfMonths={2}
                selectedDays={[modifiers.from, { from: modifiers.from, to: modifiers.to }]}
                modifiers={{ selected_start: modifiers.from, selected_end: modifiers.to, selected_range: modifiers }}
                onDayClick={handleDayClick}
                disabledDays={[
                  ...disabledDays,
                  ...getDisabledSingularDates(disabledDays, !validateDate(arrival)),
                  { before: arrival ? parseDate(dateWithoutTimezone(arrival)) : new Date() },
                  ...invalidDates,
                ]}
                weekdaysShort={['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']}
              />
              <div className="flex flex-wrap justify-center gap-20 px-20 mt-20 sm:flex-nowrap lg:justify-between sm:px-0">
                <Button
                  variant="secondary"
                  className="whitespace-nowrap text-12 rounded-full h-[34px]"
                  onClick={clearDates}
                >
                  clear dates
                </Button>
                <Button className="whitespace-nowrap uppercase text-12 rounded-full h-[34px]" onClick={confirmDates}>confirm dates</Button>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </>
  )
}

ArrivalDeparturePopover.propTypes = {
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  arrival: PropTypes.object,
  setArrival: PropTypes.func,
  departure: PropTypes.object,
  setDeparture: PropTypes.func,
  position: PropTypes.string,
  disabledDays: PropTypes.array,
  className: PropTypes.string,
  borderClassName: PropTypes.string,
}

export default ArrivalDeparturePopover
