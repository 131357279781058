import { Transition } from '@headlessui/react'
import classNames from 'classnames'
import Error from 'components/form/Error'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import { ReactChild } from 'data/types/types'

interface SearchFilterProps {
  value?: any
  label: string
  placeholder: string
  open?: boolean
  onClose?: () => void
  onClick?: () => void
  children?: ReactChild
  className?: {
    container?: string
  }
  error?: string
}

const SearchFilter = ({
  value,
  label,
  placeholder,
  open,
  children,
  onClick,
  className,
  error,
}: SearchFilterProps) => {
  return (
    <>
      <div className={classNames(
        'w-full gap-8 overflow-hidden border rounded-17 focus-within:ring-2',
        className?.container,
        {
          'border-red border-opacity-50 focus-within:ring-red focus-within:ring-opacity-20': error,
          'border-grey-250 focus-within:ring-grey-100 ': !error,
        },
      )}>
        <button
          type="button"
          onClick={onClick}
          className="w-full flex items-center justify-between px-[22px] py-16 focus-within:outline-none"
        >
          <Paragraphs.MD
            Tag="span"
            className="font-bold"
          >
            {label}
          </Paragraphs.MD>

          <Paragraphs.SM
            Tag="span"
            className={classNames('font-poppins', {
              'text-grey-300': !value,
              'text-grey-800': value,
            })}
          >
            {value
              ? value
              : placeholder
            }
          </Paragraphs.SM>
        </button>

        {
          children && (
            <Transition
              show={open}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <div className="px-[22px] pb-12">
                { children }
              </div>
            </Transition>
          )
        }
      </div>

      {error && (
        <Error className="mt-10" error={error} />
      )}
    </>
  )
}

export default SearchFilter
