import { useState } from 'react'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

export const useHasLoaded = () => {
  const { ref, inView } = useInView()

  const [hasLoaded, setHasLoaded] = useState(false)
  useEffect(() => {
    if (hasLoaded) { return }

    setHasLoaded(hasLoaded || inView)
  }, [inView, hasLoaded])

  return { ref, hasLoaded }
}
