import PublicMobileModal, { PublicMobileModalProps } from 'components/PublicMobileModal'
import logo from '../../../assets/images/logo-tagline.jpg'
import Image from 'components/Image'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import ShareButton from 'components/home/ShareButton'
import { Share } from 'data/enums/share-target'
import { getShareLink } from 'data/helpers/share'
import { useCopyToClipboard } from 'data/hooks/useCopyToClipboard'
import { ReactChild } from 'data/types/types'
import { ModalProps } from 'data/types/modal'
import { RandomRegionQuery } from 'gql/graphql'
import classNames from 'classnames'
import ProfileImage from 'components/ProfileImage'
import WishlistUsersModal from '../bookings/shared/WishlistUsersModal'
import { useEffect, useState } from 'react'
import { ShareWishlistModalProps } from '../ShareWishlistModal'

type ShareLink = {
  target: Exclude<Share, Share.CLIPBOARD>,
  icon: JSX.Element
  text?: string
} | {
  target: Share.CLIPBOARD,
  icon: JSX.Element
  text?: string
}

type ShareLinkOptionals = {
  onClick: () => void
  href?: never
} | {
  onClick?: never
  href: string
}

interface ShareModalProps extends ShareModalContentProps, Omit<PublicMobileModalProps, 'title' | 'children'> {
  children?: ReactChild
}

export interface ShareModalContentProps extends Pick<ModalProps, 'onClose'> {
  title: string
  description: string
  links?: (ShareLink & ShareLinkOptionals)[]
  icon?: ReactChild
  size?: 'sm' | 'md'
  model?: ShareWishlistModalProps['wishlist']
  randomRegion?: RandomRegionQuery
  refetch?: () => void
}

const ShareModal = ({
  isOpen,
  onClose,
  onOpen,
  refetch,
  title,
  description,
  model,
  randomRegion,
  links,
  children,
  icon,
  isChildModalOpen,
}: ShareModalProps) => {

  return (
    <PublicMobileModal
      isChildModalOpen={isChildModalOpen}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      className={{
        panel: 'flex flex-col pt-[66px] px-24 pb-50 max-w-lg',
      }}
    >
      <>
        <ShareModalContent
          title={title}
          description={description}
          links={links}
          onClose={onClose}
          refetch={refetch}
          icon={icon}
          model={model}
          randomRegion={randomRegion}
        />

        {children}
      </>
    </PublicMobileModal>
  )
}

const ShareModalContent = ({ title, description, links, onClose, icon, size, model, randomRegion, refetch }: ShareModalContentProps) => {
  const [invitedUsersWishlistModalOpen, setInvitedUsersWishlistModalOpen] = useState(false)

  useEffect(() => {
    if (invitedUsersWishlistModalOpen) {
      refetch?.()
    }
  }, [invitedUsersWishlistModalOpen])

  return (
    <>
      <div className="w-full text-center mb-25">
        {icon
          ? icon
          : <Image src={logo} alt="logo" width={110} height={60} />
        }
      </div>

      <Paragraphs.XL className="text-center mb-15 !leading-24 !font-bold">
        {title}
      </Paragraphs.XL>

      <Paragraphs.LG className="mb-40 text-center text-grey-650">
        {description}
      </Paragraphs.LG>

      {
        model && model?.users && model?.users?.length > 0 && (
          <div className="flex items-center justify-center gap-[11px] mb-30 mt-[-15px]">
            <button
              onClick={() => setInvitedUsersWishlistModalOpen(true)}
              className="flex items-center"
            >
              {
                model?.users?.map((user, index) => (
                  <ProfileImage
                    key={`user-${user.id}`}
                    user={user}
                    className={classNames(
                      'relative border-[1.5px] border-blue-50 rounded-full px-10 py-5',
                      {
                        'mr-[-6px]': index !== (model?.users?.length - 1),
                        'min-w-[28px] max-w-[28px] min-h-[28px] max-h-[28px]': size === 'sm',
                        'min-w-[32px] max-w-[32px] min-h-[32px] max-h-[32px]': size === 'md',
                      },
                    )}
                  />
                ))
              }
            </button>
          </div>
        )}


      <div className="flex flex-col gap-15 text-22">
        {links && links.map((link) => getShareButton(link, onClose))}
      </div>

      {
        model && (
          <WishlistUsersModal
            isOpen={invitedUsersWishlistModalOpen}
            wishlist={model}
            refetch={refetch}
            randomRegion={randomRegion as RandomRegionQuery}
            onClose={() => {
              setInvitedUsersWishlistModalOpen(false)
            }}
          />
        )
      }
    </>
  )
}

const getShareButton = (link: (ShareLink & ShareLinkOptionals), onClose: () => void | undefined) => {
  const { copyToClipboard } = useCopyToClipboard()
  const shareLink = getShareLink(link.target, link.href, link.text)

  switch (link.target) {
    case Share.CLIPBOARD:
      return (
        <ShareButton
          key={`share-${link.target}`}
          shareTarget={link.target}
          onClick={() => {
            copyToClipboard(shareLink)
            onClose?.()
          }}
          icon={link.icon}
        />
      )

    default:
      return (
        <ShareButton
          key={`share-${link.target}`}
          href={shareLink}
          onClick={link.onClick}
          shareTarget={link.target}
          icon={link.icon}
        />
      )
  }
}

export default ShareModal
