import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { useSearchParams } from 'data/hooks/useSearchParams'
import React, { useEffect, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

type TabGroupItem = {
  label: string
  value: string
}

export interface TabGroupProps {
  items: TabGroupItem[]
  onChange: (item: TabGroupItem) => void // index of items
  className?: {
    container?: string
    list?: string
  }
}

interface ControlledTabGroupProps extends TabGroupProps {
  control: UseFormReturn['control']
  name: string
}

interface DefaultTabGroupProps extends TabGroupProps {
  initialIndex?: number
  selectedIndex?: number
}

interface URLControlledTabGroupProps extends TabGroupProps {
  name: string
}

const Controlled = (props: ControlledTabGroupProps) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={() => (
        <Default
          {...props}
        />
      )}
    />
  )
}

const Default = ({
  items,
  onChange,
  className,
  initialIndex,
  selectedIndex,
}: DefaultTabGroupProps) => {
  const [internalSelectedTab, setInternalSelectedTab] = useState(initialIndex && initialIndex < items.length ? initialIndex : 0)

  return (
    <Tab.Group
      as="div"
      onChange={setInternalSelectedTab}
      selectedIndex={selectedIndex ?? internalSelectedTab}
      className={className?.container}
    >
      <Tab.List className={classNames(
        'flex bg-grey-150 rounded-full py-[6px] px-12 h-40 w-max',
        className?.list,
      )}>
        {items.map((option) => (
          <Tab
            key={option.value}
            value={option.value}
            className="rounded-full outline-none"
            onClick={() => onChange(option)}
          >
            {({ selected }) => (
              <div
                className={classNames('flex justify-center items-center rounded-25 px-20 h-full font-poppins text-grey-800 text-12 w-full cursor-pointer tracking-1/4 font-medium', {
                  'bg-white': selected,
                })}
              >
                {option.label}
              </div>
            )}
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  )
}

const URLControlled = ({
  items,
  onChange,
  className,
  name,
}: URLControlledTabGroupProps) => {
  const { params } = useSearchParams()
  const [selectedTab, setSelectedTab] = useState(items[0].value)

  useEffect(() => {
    setSelectedTab(
      items.find(item => item.value === params.get(name))?.value ?? items[0].value,
    )
  }, [params])

  return (
    <Default
      items={items}
      onChange={onChange}
      className={className}
      initialIndex={items.findIndex((item) => item.value === selectedTab)}
      selectedIndex={items.findIndex((item) => item.value === selectedTab)}
    />
  )
}

export default {
  Controlled,
  Default,
  URLControlled,
}
