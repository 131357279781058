import React from 'react'
import PropTypes from 'prop-types'
import ComponentLink from 'components/Link'
import classNames from 'classnames'

const Link = ({ text, href, className, block = false }) => (
  <ComponentLink
    href={href}
    className={classNames(
      'focus:outline-none group',
      {
        'w-full': block,
        'inline-flex': !block,
      },
    )}
  >
    <p className={classNames(
      'group-focus:text-primary group-hover:underline',
      className,
    )}>
      {text}
    </p>
  </ComponentLink >
)

Link.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  block: PropTypes.bool,
}

const Button = ({ text, onClick, className }) => (
  <button
    type="button"
    onClick={onClick}
    className={classNames(
      'inline-flex cursor-pointer focus:outline-none focus:text-primary hover:underline',
      className,
    )}
  >
    {text}
  </button>
)

Link.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  block: PropTypes.bool,
}

Button.propTypes = {
  text: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default {
  Link,
  Button,
}
