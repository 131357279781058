import ApiClient from 'data/api/api_client'
import placeholderImage from 'assets/images/placeholder.png'
import DetailedBookingSidebar from 'components/DetailedBookingSidebar'
import { handleServerSideErrors } from 'data/helpers/errors'
import BookingAccessProvider from 'providers/BookingAccessProvider'
import BookingGuestsTrigger from 'components/modals/BookingGuestsTrigger'
import { GetServerSidePropsContext } from 'next'
import PublicLayout, { PublicLayoutProps, PublicLayoutVariant } from 'layouts/PublicLayout'
import BookingMenuModal from 'components/home/BookingMenuModal'
import UserDashboardContent, { UserDashboardContentProps } from 'layouts/UserDashboard/UserDashboardContent'
import { RestBooking } from 'data/types/booking'
import TabGroup, { TabGroupProps } from 'components/home/common/TabGroup'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'data/hooks/useSearchParams'
import { useRouterWithQuery } from 'data/hooks/useRouterWithQuery'
import SummaryViewTab from 'components/trip-schedule/tabs/SummaryViewTab'
import UserServiceRequestModal from 'components/modals/UserServiceRequestModal'
import UserTripServiceModal from 'components/booking/trip-schedule/UserTripServiceModal'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setPendingServicesModalOpen, setSelectedService, setSelectedServiceRequest } from 'store/trip-schedule'
import BookNewTripServiceTrigger from 'components/trip-schedule/BookNewTripServiceTrigger'
import CalendarViewTab from 'components/trip-schedule/tabs/CalendarViewTab'
import Button from 'components/home/common/Button'
import { ShareArrowIcon } from 'components/icons'
import UserPendingTripServicesModal from 'components/booking/trip-schedule/pending/UserPendingTripServicesModal'
import { TripScheduleServiceStatusType } from 'gql/graphql'
import UserTripServiceShareModal from 'components/booking/trip-schedule/UserTripServiceShareModal'
import { MobileScreenWidths, useScreenWidth } from 'data/hooks/useScreenWidth'
import { TripScheduleService } from 'data/types/bookings/trip_schedule'
import { CommonParams } from 'data/enums/params'

interface UserBookingTripSchedulePageProps {
  initialData: {
    booking: RestBooking
    tripServices: TripScheduleService[]
  }
}

export enum ItineraryTab {
  DayView = 'day',
  CalendarView = 'calendar',
}

const UserBookingTripSchedulePage = ({
  initialData,
}: UserBookingTripSchedulePageProps) => {
  const { booking, tripServices } = initialData ?? {}
  const { params } = useSearchParams()
  const { updateQueryParam } = useRouterWithQuery()
  const dispatch = useDispatch()

  if (!booking) {
    return null
  }

  const {
    selectedService,
    selectedServiceRequest,
    pendingServicesModalOpen,
  } = useSelector((state: RootState) => ({
    selectedService: state.tripSchedule.selectedService,
    selectedServiceRequest: state.tripSchedule.selectedServiceRequest,
    pendingServicesModalOpen: state.tripSchedule.pendingServicesModalOpen,
  }))

  const itineraryFilters: TabGroupProps['items'] = [
    { label: 'Day View', value: ItineraryTab.DayView },
    { label: 'Calendar View', value: ItineraryTab.CalendarView },
  ]

  const [selectedFilter, setSelectedFilter] = useState<TabGroupProps['items'][0]>(
    itineraryFilters.find((filter) => filter.value === params.get(CommonParams.TAB)) ?? itineraryFilters[0],
  )

  const [isShareModalOpen, setIsShareModalOpen] = useState(false)

  useEffect(() => {
    updateQueryParam('tab', selectedFilter.value)
  }, [selectedFilter])

  const publicLayoutProps: Omit<PublicLayoutProps, 'children'> = {
    variant: PublicLayoutVariant.Home,
    mobileNavBar: {
      visible: true,
      back: true,
      fade: true,
      className: {
        image: {
          carousel: 'h-[166px]',
          overlay: 'absolute w-max bottom-0 z-10 h-max bottom-[13px] right-[22px]',
        },
      },
      title: <BookingMenuModal booking={booking} />,
      images: [{
        url: booking?.property.main_image ?? placeholderImage.src,
        alt_text: booking?.property.main_image_alt_text,
      }],
      imageOverlay: (
        <div className="relative flex items-end justify-end h-full pr-[22px] pb-[13px]">
          <BookingGuestsTrigger
            booking={booking}
            showLabel={false}
            size="sm"
          />
        </div>
      ),
    },
  }

  const { isMobile } = useScreenWidth(MobileScreenWidths.LG)

  const userDashboardContentProps: Omit<UserDashboardContentProps, 'children'> = {
    sidebar: (
      <DetailedBookingSidebar booking={booking} bookingNumber={booking?.id}>
        <div className="flex flex-col gap-[7px]">
          <Button
            onClick={() => dispatch(setPendingServicesModalOpen(true))}
            variant="secondary"
            style="modal"
            className={{ button: '!text-13 !py-12 whitespace-nowrap' }}
            block
          >
            Approve Pending Services
          </Button>
          <Button
            onClick={() => setIsShareModalOpen(true)}
            variant={booking?.booking_information?.length > 0 ? 'secondary' : 'primary'}
            style="modal"
            className={{ button: '!text-13 !py-12' }}
            block
            icon={<ShareArrowIcon />}
          >
            Share Trip Itinerary
          </Button>
        </div>
      </DetailedBookingSidebar>
    ),
    title: {
      regular: 'Stay at',
      italic: booking.property.title,
      leftElement: <TabGroup.URLControlled
        items={itineraryFilters}
        onChange={setSelectedFilter}
        name="tab"
      />,
      rightElement: <BookingGuestsTrigger
        booking={booking}
        showLabel={false}
        className={{
          container: 'justify-end',
        }}
        size="md"
      />,
    },
    className: {
      container: 'lg:pt-0 lg:!mt-0 !px-0 !h-[calc(100vh-226px)] lg:!h-full overflow-y-auto',
      content: 'relative',
    },
  }

  return (
    <PublicLayout {...publicLayoutProps}>
      <BookingAccessProvider booking={booking}>
        <UserDashboardContent {...userDashboardContentProps}>

          {selectedFilter.value === ItineraryTab.DayView
            ? <SummaryViewTab
              booking={booking}
              tripServices={tripServices ?? []}
            />
            : null
          }

          {selectedFilter.value === ItineraryTab.CalendarView
            ? (isMobile
              ? <SummaryViewTab
                booking={booking}
                tripServices={tripServices ?? []}
              />
              : <CalendarViewTab
                booking={booking}
                tripServices={tripServices ?? []}
              />
            )
            : null
          }

          <UserServiceRequestModal
            isOpen={!!selectedServiceRequest}
            onClose={() => dispatch(setSelectedServiceRequest(null))}
            serviceRequestId={selectedServiceRequest}
          />

          <UserTripServiceModal
            isOpen={!!selectedService}
            onClose={() => dispatch(setSelectedService(null))}
            serviceId={selectedService}
          />

          {selectedFilter.value === ItineraryTab.DayView || isMobile
            ? <BookNewTripServiceTrigger
              bookingId={booking.id.toString()}
            />
            : null
          }
        </UserDashboardContent>
      </BookingAccessProvider>

      <UserPendingTripServicesModal
        isOpen={pendingServicesModalOpen}
        onClose={() => dispatch(setPendingServicesModalOpen(false))}
        bookingId={booking.id.toString()}
        initialTab={TripScheduleServiceStatusType.PendingApproval}
      />

      <UserTripServiceShareModal
        booking={booking}
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
      />
    </PublicLayout>
  )
}

export async function getServerSideProps ({ req, query, resolvedUrl }: GetServerSidePropsContext) {
  try {
    const [booking, tripServices] = await Promise.all([
      ApiClient.withReq(req).bookings.getBooking(query.id as string, {
        includes: [
          'property', 'property.media', 'property.housekeepingServices', 'property.linenTowelsServices', 'property.staffServices', 'property.otherServices', 'property.propertyHouseRules',
          'sharedUsers', 'sharedUserInvites',
          'servicePayments', 'servicePayments.tripSchedule', 'servicePayments.parts', 'servicePayments.parts.payment', 'servicePayments.parts.payment.paymentGroup',
          'client', 'client.address', 'client.userPreferences', 'client.teamContacts', 'client.media', 'client.bookings',
          'bookingAgent', 'bookingAgent.address', 'bookingAgent.userPreferences', 'bookingAgent.teamContacts', 'bookingAgent.media', 'bookingAgent.bookings',
          'digitalConcierge', 'digitalConcierge.address', 'digitalConcierge.userPreferences', 'digitalConcierge.teamContacts', 'digitalConcierge.media', 'digitalConcierge.bookings',
          'tripScheduleServices', 'bookingPropertyRates', 'conversations', 'client',
        ],
      }),
      ApiClient.tripSchedule.getTripScheduleServices(query.id, {
        include_payment_statuses: true,
        include_service_survey: true,
      }),
    ])

    return {
      props: {
        initialData: {
          booking: booking ?? {},
          tripServices: tripServices ?? [],
        },
      },
    }
  } catch (e) {
    return handleServerSideErrors(e, resolvedUrl, false)
  }
}


export default UserBookingTripSchedulePage
