import dayjs from 'dayjs'
import Accordion from 'components/accordion'
import { AttachmentIcon, ClockOutlineIcon, LoadingIcon } from 'components/icons'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import Image from 'components/Image'
import UserTripServicePill from './UserTripServicePill'
import { useQuery as useGraphQLQuery } from 'urql'
import { getFormattedTime, getServiceDetails } from 'data/helpers/survey/tripSchedule'
import PublicMobileModal from 'components/PublicMobileModal'
import Pill from 'components/home/Pill'
import { useMemo, useState } from 'react'
import classNames from 'classnames'
import { TripScheduleService, TripScheduleServiceStatusType } from 'gql/graphql'
import { graphql } from 'gql'
import { useQuery } from 'react-query'
import ApiClient from 'data/api/api_client'
import { ModalProps } from 'data/types/modal'
import { pluraliseWithNumber } from '@ama-selections/ui'
import ConnectedServicesModal from './pending/ConnectedServicesModal'
dayjs.extend(advancedFormat)

export type UserTripServiceQuery = TripScheduleService

const USER_TRIP_SERVICE_MODAL_QUERY = graphql(`
  query UserTripService ($id: ID!) {
    tripScheduleService(id: $id) {
      id
      
      date
      start_time
      end_time

      service_provider_contact
      service_details
      additional_information
      included
      address

      group {
        id
        title
        subtitle

        status
        days {
          id
          date
          start_time
          end_time
        }

        service {
          id
          survey_slug
        }

        service_provider_contact
        service_details
        additional_information
        included
        address

        main_image {
          id
          url
        }

        uploaded_files {
          id
          name
          url
        }

        booking {
          id

          arrival_information_count
          departure_information_count
        }

        service_request {
          id
          request_id
          survey_id
          trip_service {
            id
          }
          status
          details {
            question
            answer
          }
          service {
            id
            title
          }
          user {
            id
            first_name
            last_name
          }
          booking {
            id
          }
          created_at
        }
      }
    }
}
`)

export interface UserTripServiceModalProps extends ModalProps {
  serviceId?: string
}

const UserTripServiceModal = ({
  serviceId,
  isOpen,
  onClose = () => { },
}: UserTripServiceModalProps) => {
  if (!serviceId) {
    return null
  }

  const [connectedServiceModalOpen, setConnectedServiceModalOpen] = useState(false)

  const accordionItemProps = {
    style: {
      container: 'font-bold tracking-1/2 text-14 lg:text-15 leading-20 py-20 !border-grey-150 !border-opacity-100',
      items: 'pl-[0px]',
    },
  }

  const [{ data, fetching }] = useGraphQLQuery({
    query: USER_TRIP_SERVICE_MODAL_QUERY,
    variables: {
      id: serviceId!,
    },
    pause: !serviceId || !isOpen,
  })

  const service = useMemo(() => data?.tripScheduleService, [data])

  const showAccordion = useMemo(
    () => (
      service?.service_provider_contact
      || service?.service_details
      || service?.additional_information
      || service?.included
      || service?.address
      || service?.group?.service_provider_contact
      || service?.group?.service_details
      || service?.group?.additional_information
      || service?.group?.included
      || service?.group?.address
    ),
    [service],
  )

  const { data: tripService } = useQuery(
    ['tripScheduleService', service?.id],
    () => ApiClient.tripSchedule.getTripScheduleService(service!.group.booking.id, service!.id),
    {
      enabled: !!service,
    },
  )

  return (
    <PublicMobileModal
      isOpen={isOpen} onClose={onClose} transition="center"
      className={{
        panel: 'w-full h-[80vh] lg:h-full max-h-[860px] lg:max-h-[750px] rounded-10 max-w-[600px]',
        closeButton: '!bg-transparent !text-white hover:!bg-white hover:!bg-opacity-25',
      }}
      isChildModalOpen={connectedServiceModalOpen}
    >
      {
        (!service || fetching)
          ?          (
            <div className="flex items-center justify-center w-full py-50">
              <LoadingIcon />
            </div>
          )
          : (
            <div className="flex flex-col h-full max-h-[860px] lg:max-h-[750px]">
              {
                service.group.main_image?.url && (
                  <div className="w-full min-h-[220px] relative">
                    <div className="absolute z-10 flex flex-col items-end gap-15 top-16 left-21">
                      <UserTripServicePill
                        service={{
                          title: service.group.title,
                          status: service.group.status,
                        }}
                        booking={{ id: service.group.booking.id }}
                        bookingInformation={{
                          arrival: service.group.booking.arrival_information_count > 0,
                          departure: service.group.booking.departure_information_count > 0,
                        }}
                        onClose={onClose}
                      />
                    </div>

                    <Image
                      src={service.group.main_image.url}
                      className="object-cover object-center"
                      alt={service.group.title}
                      layout="fill"
                    />
                  </div>
                )
              }
              <div className="w-full px-50 py-25 lg:overflow-y-auto">
                {/* Date */}
                <p className="font-bold uppercase text-grey-650 text-14 leading-20 tracking-1/2">
                  {dayjs(service.date).format('MMMM Do')}
                </p>

                {/* Name */}
                <div className="flex flex-col mb-10">
                  <h2 className="mt-10 mb-3 font-serif text-grey-900 leading-[34px] -tracking-1/2 text-[26px] font-bold">
                    {service.group.title}
                  </h2>
                  <p>
                    {service.group.subtitle}
                  </p>
                </div>

                <div className={classNames(
                  'flex items-center gap-[6px] text-grey-700',
                  {
                    'mb-25': service?.group?.uploaded_files?.length && showAccordion,
                  },
                )}>
                  <ClockOutlineIcon className="text-14 stroke-[1.5]" />
                  <p className="text-14 leading-22 tracking-1/2">
                    {getFormattedTime(service.start_time, service.end_time)}
                  </p>
                </div>

                <div className="flex flex-wrap items-center justify-start gap-5">
                  {
                    service.group?.uploaded_files && service?.group?.uploaded_files?.map((file) => (
                      file.url && (
                        <Pill
                          key={file.id}
                          href={file.url}
                          target="_blank"
                          variant="grey"
                          size="10"
                          prefix={<AttachmentIcon />}
                        >
                          {file.name}
                        </Pill>
                      )
                    ))
                  }
                </div>

                {
                  (service.group.status === TripScheduleServiceStatusType.Draft && service.group.service_request?.survey_id)
                    ? (
                      <div className="w-full pr-20 overflow-y-auto">
                        <p className="pt-20 antialiased text-grey-800 min-h-[140px] text-14 lg:text-15 whitespace-pre-wrap">
                          {
                            (service.group.service_request?.details ?? []).map((item, index) =>
                              <span key={index}>
                                <b>{item?.question}</b><br />{item?.answer}<br /><br />
                              </span>,
                            )
                          }
                        </p>
                      </div>
                    )
                    : <>
                      {
                        showAccordion && (
                          <hr className="mt-20 bg-transparent border-t border-grey-150" />
                        )
                      }

                      <Accordion>
                        {
                          (service.service_provider_contact || service.group?.service_provider_contact) && (
                            <Accordion.Item title="Service Provider Contact" {...accordionItemProps} defaultOpen>
                              <p className="text-grey-750 text-14 leading-[27px] whitespace-pre-line">
                                {service.service_provider_contact ?? service.group?.service_provider_contact}
                              </p>
                            </Accordion.Item>
                          )
                        }
                        {
                          (service.service_details || service.group?.service_details) && (
                            <Accordion.Item title="Service Details" {...accordionItemProps} defaultOpen>
                              <p className="text-grey-750 text-14 leading-[27px] whitespace-pre-line">
                                {getServiceDetails(tripService)}
                              </p>
                            </Accordion.Item>
                          )
                        }
                        {
                          (service.additional_information || service.group?.additional_information) && (
                            <Accordion.Item title="Additional Information" {...accordionItemProps} defaultOpen>
                              <p className="text-grey-750 text-14 leading-[27px] whitespace-pre-line">
                                {service.additional_information ?? service.group?.additional_information}
                              </p>
                            </Accordion.Item>
                          )
                        }
                        {
                          (service.included || service.group?.included) && (
                            <Accordion.Item title="Included" {...accordionItemProps} defaultOpen>
                              <p className="text-grey-750 text-14 leading-[27px] whitespace-pre-line">
                                {service.included ?? service.group?.included}
                              </p>
                            </Accordion.Item>
                          )
                        }
                        {
                          (service.address ?? service.group?.address) && (
                            <Accordion.Item title="Address" {...accordionItemProps} defaultOpen>
                              <p className="text-grey-750 text-14 leading-[27px] whitespace-pre-line">
                                {service.address ?? service.group?.address}
                              </p>
                            </Accordion.Item>
                          )
                        }
                      </Accordion>
                    </>
                }
              </div>

              {
                (service.group.days?.length ?? 0) > 1 && (
                  <div className="sticky bottom-0 flex justify-center pt-10 pb-20 mt-auto bg-white">
                    <Pill
                      size="14"
                      variant="primary"
                      onClick={() => setConnectedServiceModalOpen(true)}
                    >
                      {pluraliseWithNumber((service.group.days?.length ?? 1) - 1, 'Connected Service')}
                    </Pill>

                    <ConnectedServicesModal
                      tripGroup={service.group}
                      isOpen={connectedServiceModalOpen}
                      onClose={() => setConnectedServiceModalOpen(false)}
                    />
                  </div>
                )
              }
            </div>
          )
      }
    </PublicMobileModal>
  )
}

export default UserTripServiceModal
