import classNames from 'classnames'
import { ReactChild } from 'data/types/types'
import React from 'react'

export interface NavSectionProps {
  children: ReactChild
  heading?: string
  className?: {
    container?: string
    heading?: string
  }
}

export const NavSection = ({
  children,
  heading,
  className,
}: NavSectionProps) => (
  <div className={classNames(
    'flex flex-col px-20',
    className?.container,
  )}>
    {
      heading && (
        <h2 className={classNames(
          'tracking-1/2 text-14 leading-24 text-blue-1000 mb-[19px] uppercase',
          className?.heading,
        )}>
          {heading}
        </h2>
      )
    }

    {children}
  </div>
)
