import { Controller } from 'react-hook-form'
import NumberInput, { NumberInputPropTypes } from './NumberInput'

interface ControlledNumberInputProps extends Omit<NumberInputPropTypes, 'onChange'|'value'> {
  name: string
  control: any
}

export default function ControlledNumberInput ({
  name,
  control,
  defaultValue,
  ...props
}: ControlledNumberInputProps) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <NumberInput
          value={value}
          onChange={onChange}
          {...props}
        />
      )}
    />
  )
}
