import PublicMobileModal from 'components/PublicMobileModal'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import { SharedUser } from './SharedUser'
import { RandomRegionQuery, User } from 'gql/graphql'
import WishlistAccessModal from 'components/modals/WishlistAccessModal'
import { useState } from 'react'
import { InvitedWishlistUser } from 'data/types/user'
import Button from 'components/home/common/Button'
import useAuth from 'data/hooks/User/useAuth'
import { ShareModalContentProps } from 'components/modals/home/ShareModal'

interface WishlistUsersModalProps {
  wishlist: ShareModalContentProps['model']
  randomRegion?: RandomRegionQuery
  isOpen: boolean
  onClose: () => void
  refetch?: () => void
}

const WishlistUsersModal = ({
  wishlist,
  randomRegion,
  isOpen,
  onClose = () => { },
  refetch,
}: WishlistUsersModalProps) => {
  const [selectedUser, setSelectedUser] = useState<User | InvitedWishlistUser>()
  const authenticatedUser = useAuth()

  const handleOnSubmit = (user: User | InvitedWishlistUser) => {
    if (wishlist?.owner?.id == authenticatedUser?.user?.id) {
      setSelectedUser(user)
    }
  }

  return (
    <PublicMobileModal
      isOpen={isOpen}
      title="Invited Friends"
      isChildModalOpen={selectedUser !== undefined}
      onClose={onClose}
      backIcon={true}
      className={{
        panel: 'flex flex-col px-24 pb-20 lg:pb-30 max-w-lg',
      }}
    >

      <div className="flex flex-col gap-10 mb-48 mt-25">
        {wishlist?.users?.filter(user => user?.id !== wishlist?.owner?.id).length === 0 && !wishlist?.userInvites && (
          <Paragraphs.MD className="my-20 text-center text-grey-750 leading-20 text-14">No Invitations</Paragraphs.MD>
        )}

        {wishlist?.users?.filter(user => user?.id !== wishlist?.owner?.id).map((user) => (
          <SharedUser
            user={user as User}
            key={user.id}
            label="Invited"
            showTick={true}
            onClick={() => handleOnSubmit(user as User)}
          />
        ))}

        {wishlist?.userInvites?.map((user) => (
          <SharedUser
            user={user}
            key={user.id}
            label="Invited"
            showTick={false}
            onClick={() => handleOnSubmit(user)}
          />
        ))}
      </div>


      <div className="w-full max-w-[340px] flex mx-auto justify-center items-center">
        <Button
          block
          onClick={onClose}
          style="modal"
          variant="primary"
        >
          Invite More Friends
        </Button>
      </div>

      <WishlistAccessModal
        wishlist={wishlist}
        randomRegion={randomRegion}
        isOpen={selectedUser !== undefined}
        onClose={() => {
          refetch?.()
          setSelectedUser(undefined)
        }}
        refetch={refetch}
        user={selectedUser as User}
      />

    </PublicMobileModal>
  )
}

export default WishlistUsersModal
