import { useEffect, useState } from 'react'
import { HamburgerIcon, UserCircleIcon } from 'components/icons'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { useScreenWidth } from 'data/hooks/useScreenWidth'
import { setLoginModalOpen } from 'store/auth'
import { RootState } from 'store'
import NavigationModal from 'components/modals/NavigationModal'
import CustomerProfileModal from 'components/modals/CustomerProfileModal'
import IconButton from 'components/IconButton'
import NotificationsPopover from 'components/popovers/NotificationsPopover'
import { getProfileImage } from '@ama-selections/ui'


const UserProfileTrigger = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const [profileModalOpen, setProfileModalOpen] = useState(false)
  const [navigationModalOpen, setNavigationModalOpen] = useState(false)

  const isMobile = useScreenWidth(1024).isMobile

  const { user, checked } = useSelector((state: RootState) => ({
    user: state.auth.user,
    checked: state.auth.checked,
  }))

  const openModal = () => {
    if (checked && user) {
      setProfileModalOpen(true)
    } else if (isMobile) {
      setNavigationModalOpen(true)
    } else {
      dispatch(setLoginModalOpen(true))
    }
  }

  useEffect(() => {
    setNavigationModalOpen(false)
    setProfileModalOpen(false)
  }, [router])

  return (
    <>
      {
        isMobile
          ? (
            <IconButton
              variant="navbar"
              onClick={() => openModal()}
            >
              <HamburgerIcon className="my-auto stroke-1 text-18" />
              <div className="sr-only">Menu Button</div>
            </IconButton>
          )
          : (
            <div className="flex items-center justify-end border-none cursor-pointer gap-15 text-30 whitespace-nowrap">
              {
                checked && user
                  ? (
                    <div className="flex items-center gap-24" >
                      <NotificationsPopover />

                      <button className="flex items-center gap-15" onClick={() => setProfileModalOpen(true)}>
                        {
                          getProfileImage(user)
                            ? <img src={getProfileImage(user)} className="rounded-full h-30 w-30" />
                            : <UserCircleIcon />
                        }
                      </button>
                    </div>
                  )
                  : (
                    <button className="flex items-center" onClick={() => dispatch(setLoginModalOpen(true))}>
                      <p className="uppercase text-14 text-grey-800 mr-18">Login</p>
                      <UserCircleIcon />
                    </button>
                  )
              }
            </div>
          )
      }

      {navigationModalOpen && (
        <NavigationModal
          isOpen={navigationModalOpen}
          onClose={() => setNavigationModalOpen(false)}
          onLogin={() => {
            setNavigationModalOpen(false)
            dispatch(setLoginModalOpen(true))
          }}
        />
      )}

      <CustomerProfileModal
        isOpen={profileModalOpen}
        onClose={() => setProfileModalOpen(false)}
      />
    </>
  )
}

export default UserProfileTrigger
