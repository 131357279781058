import classNames from 'classnames'
import Button from 'components/home/common/Button'
import Headers from 'components/home/common/typography/Headers'
import { ArrowLeftIcon, CrossIcon, ShareIcon } from 'components/icons'
import Link from 'components/Link'
import WishlistModalTrigger from 'components/modals/triggers/WishlistModalTrigger'
import { useHasScrolled } from 'data/hooks/useHasScrolled'
import { ReactChild } from 'data/types/types'
import { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import ArticleShareModal from 'pages/magazine/ArticleShareModal'
import { useState } from 'react'
import { FreeMode, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

export interface MobileStickyBannerProps {
  className?: {
    container?: string,
    child?: string,
  }
  title?: string | ReactChild
  back?: boolean
  close?: {
    enabled: boolean
    onClick?: () => void
    href?: LinkProps['href']
  }
  wishlist?: {
    id: string
    model: 'Property'
  }
  share?: {
    href: string
  }
  fade?: boolean | string,
  links?: {
    label: string
    href: string
    active?: boolean
  }[]
}

const MobilePublicStickyBanner = ({
  title,
  className,
  fade = false,
  links,
  close,
  share,
  back,
  wishlist,
}: MobileStickyBannerProps) => {

  const router = useRouter()
  const hasScrolled = useHasScrolled(0)
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)

  const bannerClassNames = 'top-0 z-10 flex flex-col transition-colors w-screen'

  return (<>
    <div
      className={classNames(
        bannerClassNames,
        className?.container,
        {
          'sticky': !fade,
          'fixed': fade,
          'bg-white': (fade && hasScrolled) || !fade,
          'bg-transparent': fade && !hasScrolled,
        },
      )}
    >
      <div className={classNames(
        'flex items-center justify-between gap-20 px-20 pt-safe-offset-10',
        {
          'pb-10': !links,
          'z-[11]': fade,
        },
        className?.child,
      )}>
        <div className="h-[28px]">
          {/* Filler Block to Correct the Centre */}
          {!back && (
            <div className="w-[28px] h-[28px]" />
          )}

          { back && (
            <BannerButton
              icon={<ArrowLeftIcon />}
              onClick={() => router.back()}
              fade={fade}
              hasScrolled={hasScrolled}
            />
          )}
        </div>

        { typeof title === 'string'
          ? (
            <Headers.H1 className={classNames({
              'text-grey-900': (fade && hasScrolled) || !fade,
              'text-white': fade && !hasScrolled,
            })}>
              {title}
            </Headers.H1>
          )
          : title
        }

        <div className="h-[28px]">
          { share && (
            <BannerButton
              icon={<ShareIcon />}
              onClick={() => setIsShareModalOpen(true)}
              fade={fade}
              hasScrolled={hasScrolled}
            />
          )}

          {
            close && close.enabled && (
              <BannerButton
                icon={<CrossIcon />}
                onClick={close.onClick}
                href={close.href}
                fade={fade}
                hasScrolled={hasScrolled}
              />
            )
          }

          {
            wishlist && (
              <WishlistModalTrigger
                propertyId={wishlist.id}
                buttonVariant="bigProperty"
                className={classNames(
                  'mt-5',
                  {
                    'hover:bg-grey-200': (fade && hasScrolled) || !fade,
                    'hover:bg-black hover:bg-opacity-20': fade && !hasScrolled,
                  },
                )}
              />
            )
          }

          {/* Filler Block to Correct the Centre */}
          {!share && !close && !wishlist && (
            <div className="w-[28px] h-[28px]" />
          )}
        </div>
      </div>

      { fade && (
        <div className={classNames(
          'absolute top-0 left-0 w-full bg-gradient-to-b from-black/50 to-transparent transition-opacity pointer-events-none',
          typeof fade === 'string' && fade,
          {
            'opacity-0': hasScrolled,
            'opacity-100': !hasScrolled,
            'h-[180px]': fade === true,
          },
        )} />
      )}

      <Swiper
        modules={[FreeMode, Mousewheel]}
        slidesPerView="auto"
        freeMode={true}
        className="max-w-fit"
        mousewheel={{
          forceToAxis: true,
        }}
      >
        {
          links?.map((link) => (
            <SwiperSlide key={link.label} className="text-16 w-max">
              <Button
                type="button"
                variant="tab"
                href={link.href}
                className={{
                  button: classNames({
                    'text-grey-800 border-b-2 border-primary-fresh decoration-[2.5px]': link.active,
                    'text-grey-700': !link.active,
                  }),
                }}
              >
                {link.label}
              </Button>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>

    <ArticleShareModal
      isOpen={isShareModalOpen}
      onClose={() => {setIsShareModalOpen(false)}}
      href={router.asPath}
    />
  </>
  )
}

interface BannerButtonProps {
  fade: boolean | string,
  hasScrolled: boolean,
  onClick?: () => void,
  href?: LinkProps['href'],
  icon: ReactChild
}

const BannerButton = ({
  fade = false,
  hasScrolled,
  onClick,
  href,
  icon,
}: BannerButtonProps) => {
  const styles = classNames('block stroke-2 text-24 transition-colors p-2 rounded-4 h-[28px]', {
    'text-black hover:bg-grey-200': (fade && hasScrolled) || !fade,
    'text-white hover:bg-black hover:bg-opacity-20': fade && !hasScrolled,
  })

  if (href) {
    return (
      <Link
        className={styles}
        onClick={onClick}
        href={href}
      >
        {icon}
      </Link>
    )
  }

  return (
    <button
      type="button"
      className={styles}
      onClick={onClick}
    >
      {icon}
    </button>
  )
}

export default MobilePublicStickyBanner
