import PublicMobileModal from 'components/PublicMobileModal'
import React from 'react'
import MiniServiceSearch, { MiniServiceSearchClassNames } from './MiniServiceSearch'
import { useForm, useFormContext } from 'react-hook-form'
import { DashboardSearchFormFields } from 'data/types/home/search'
import classNames from 'classnames'

interface ServiceSpecificSearchModalProps {
  isOpen: boolean
  onClose: () => void
  bookingId?: string
  selectedRegion?: string
  showDisclaimer?: boolean
  className?: {
    panel?: string
    content?: MiniServiceSearchClassNames
  }
}

const ServiceSpecificSearchModal = ({
  isOpen,
  onClose,
  bookingId,
  selectedRegion,
  showDisclaimer = true,
  className,
}: ServiceSpecificSearchModalProps) => {
  const { setValue } = useFormContext<DashboardSearchFormFields>() ?? useForm<DashboardSearchFormFields>()

  return (
    <PublicMobileModal
      isOpen={isOpen}
      onClose={onClose}
      className={{
        panel: classNames('h-full flex flex-col', className?.panel),
      }}
      title="Book a Service"
    >
      <MiniServiceSearch
        showDisclaimer={showDisclaimer}
        onSearchChange={(search) => setValue('search', search)}
        className={{
          ...className?.content,
          container: 'px-24 pt-24 flex flex-col min-h-0',
          searchbar: 'px-24',
          grid: {
            container: '!overflow-y-auto pt-10 pb-40 px-24',
          },
        }}
        selectedRegion={selectedRegion}
        bookingId={bookingId}
      />

    </PublicMobileModal>
  )
}

export default ServiceSpecificSearchModal
