import { useState } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { RandomRegionQuery, User } from 'gql/graphql'
import { useMutation } from 'urql'
import PublicMobileModal from 'components/PublicMobileModal'
import Image from 'components/Image'
import Button from 'components/home/common/Button'
import Notification from 'components/Notification'
import { graphql } from 'gql'
import placeholderImage from 'assets/images/placeholder.png'
import useErrorHandler from 'data/hooks/useErrorHandler'
import ConfirmPublicModal from './ConfirmPublicModal'
import { ShareModalContentProps } from './home/ShareModal'

const REVOKE_INVITE_TO_WISHLIST = graphql(`
  mutation revokeInviteToWishlist($input: RevokeInviteToWishlistInput!) {
    revokeInviteToWishlist(input: $input) 
  }
`)

const REMOVE_USER_FROM_WISHLIST_MUTATION = graphql(`
  mutation removeUserFromWishlist($input: RemoveUserFromWishlistInput!) {
    removeUserFromWishlist(input: $input)
  }
`)

interface WishlistAccessModalProps {
  wishlist: ShareModalContentProps['model']
  randomRegion?: RandomRegionQuery
  user: User
  isOpen?: boolean
  onClose: () => void
  refetch?: () => void
}

const WishlistAccessModal = ({
  wishlist,
  randomRegion,
  user,
  isOpen = false,
  onClose,
  refetch,
}: WishlistAccessModalProps) => {
  const handleError = useErrorHandler()
  const { notify } = useNotifier()
  const [error, setError] = useState(null)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const [{ fetching: isRevokingInvite }, revokeInvite] = useMutation(REVOKE_INVITE_TO_WISHLIST)

  const [{ fetching: isRemovingUser }, removeUser] = useMutation(REMOVE_USER_FROM_WISHLIST_MUTATION)

  const handleUserMutation = () => {
    if (!wishlist?.id) {
      return
    }

    if (user?.first_name) {
      removeUser({
        input: {
          wishlistId: wishlist?.id,
          userId: user?.id,
        },
      })
        .then((response) => {
          if (response.error) {
            notify(
              <Notification type="error" message="Failed to revoke access" />,
            )
            handleError(error, setError)
          }
          notify(
            <Notification type="success" message="User Access Removed" />,
          )
          refetch?.()
          onClose?.()
          setError(null)
        })
    } else {
      revokeInvite({
        input: {
          wishlistId: wishlist?.id,
          email: user?.email,
        },
      })
        .then((response) => {
          if (response.error) {
            notify(
              <Notification type="error" message="Failed to revoke invite" />,
            )
            handleError(error, setError)
          }
          notify(
            <Notification type="success" message="User Invite Revoked" />,
          )
          refetch?.()
          onClose?.()
          setError(null)
        })
    }
  }

  return (
    <PublicMobileModal
      isOpen={isOpen}
      onClose={onClose}
      isChildModalOpen={isConfirmationModalOpen}
      className={{
        panel: 'max-w-[375px] max-h-[650px] pt-[66px] pb-40 !px-25',
      }}
    >
      <div className="flex justify-center">
        <Image
          src={randomRegion?.randomRegion?.main_media?.url ?? placeholderImage}
          alt={randomRegion?.randomRegion?.main_media?.file_name ?? 'Random Region Image'}
          width="197"
          height="120"
          className="rounded-[10px]"
        />
      </div>
      <div className="mt-[23px]">
        <p className="text-center text-18 leading-[27px] max-w-[275px] mx-auto">
          {
            user?.first_name
              ? (
                <p>
                  Are you sure you want to remove <strong>{user?.first_name} {user?.last_name}</strong> from this Wishlist?
                </p>
              )
              : (
                <p>
                  Are you sure you want to remove the invite for <strong>{user?.email}</strong> from this Wishlist?
                </p>
              )
          }
        </p>

        <Button
          style="modal"
          block
          isLoading={isRevokingInvite || isRemovingUser}
          className={{
            button: 'mt-30',
          }}
          onClick={() => setIsConfirmationModalOpen(true)}
        >
          {
            user?.first_name ? 'Remove from Wishlist' : 'Revoke Invite'
          }
        </Button>

        {error
          ? <p>{error}</p>
          : null
        }
      </div>

      <ConfirmPublicModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        action={() => {
          handleUserMutation()
          setIsConfirmationModalOpen(false)
        }}
        message={`Are you sure you want to revoke ${user?.first_name ? 'access' : 'invite'} for the Wishlist ${wishlist?.title}?`}
      />

    </PublicMobileModal>
  )
}

export default WishlistAccessModal
