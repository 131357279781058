class UnauthenticatedError extends Error {
  constructor (message, status) {
    super(message, status)
    this.name = 'UnauthenticatedError'
  }
}

class ForbiddenError extends Error {
  constructor (message, status) {
    super(message, status)
    this.name = 'ForbiddenError'
  }
}

class NotFoundError extends Error {
  constructor (message, status) {
    super(message, status)
    this.name = 'NotFoundError'
  }
}

export { UnauthenticatedError, ForbiddenError, NotFoundError }
