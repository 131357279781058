import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ChevronDownIcon, LoadingIcon } from 'components/icons'
import { formatDateReadable } from 'data/helpers/dates'
import { useRef } from 'react'

const BookingSelectListbox = ({ bookings, selectedBooking, setSelectedBooking, emptyStateText = 'No Bookings Found' }) => {
  const listbox = useRef(null)
  const [show, setShow] = useState(false)

  const listboxClick = () => {
    if (bookings?.length > 0) {
      setShow(!show)
    }
  }
  return (
    <div ref={listbox} className="relative w-full h-[60px]" onClick={listboxClick} onBlur={() => setShow(false)} id="listbox" tabIndex={0}>
      <div
        className={`
            absolute left-0 w-full ${show ? 'max-h-[250px]' : 'h-[60px]'} flex flex-col
            transition-all overflow-hidden bg-white duration-[400ms] rounded-[4px] text-grey-850
            border border-grey-750 border-opacity-40`}
      >
        <div className={`cursor-pointer min-w-[60px] text-14 flex items-center px-20 py-10 h-[60px] min-h-[60px] justify-between ${selectedBooking ? 'underline font-bold' : 'font-normal'}`}>
          {bookings === undefined
            ? <LoadingIcon className="mx-auto" />
            : <>
              <div>
                {bookings?.length > 0
                  ? !selectedBooking
                    ? 'Select Trip'
                    : selectedBooking?.property.title + ', ' + selectedBooking?.property.region.value
                  : emptyStateText
                }
              </div>

              <ChevronDownIcon className={`text-22 text-grey-800 ${show && 'rotate-180'} transition-all duration-[400ms]`} />
            </>
          }
        </div>

        <div className={`z-[1] cursor-pointer w-full flex transition-all duration-[400ms] flex-col overflow-auto  ${show ? 'mt-0 scale-100 pointer-events-auto opacity-100' : '-mt-[50px] scale-y-50 pointer-events-none opacity-0'}`}>
          {bookings?.length > 0 && bookings?.map((item) => (
            <div
              key={item.id}
              className="group relative pl-30 pr-10 py-8 cursor-pointer select-none bg-white hover:bg-primary-light text-14 leading-20"
              onClick={() => setSelectedBooking(item)}
            >
              <div className="font-bold group-hover:underline text-grey-800">
                {item.property.title}, {item.property.region.value}
              </div>
              <div className="font-normal text-grey-650">
                From {formatDateReadable(item.arrival)} to {formatDateReadable(item.departure)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div >
  )
}

BookingSelectListbox.propTypes = {
  bookings: PropTypes.array,
  selectedBooking: PropTypes.object,
  setSelectedBooking: PropTypes.func,
  emptyStateText: PropTypes.string,
}

BookingSelectListbox.displayName = 'BookingSelectListbox'

export default BookingSelectListbox
