import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MobileSearchbarContent from 'components/mobile/MobileSearchbarContent'
import PublicModal from 'components/PublicModal'
import { useDispatch } from 'react-redux'
import { setDestinationsOpen } from 'store/searchbar'
import { useRouter } from 'next/router'

const MobileDestinationsModal = ({ isOpen, onClose }) => {
  const [selectedDestination, setSelectedDestination] = useState({ value: '' })
  const router = useRouter()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setDestinationsOpen(isOpen))
  }, [isOpen])

  useEffect(() => {

    if (selectedDestination.region && selectedDestination.subregion) {
      router.push(`/${selectedDestination.country.slug}/${selectedDestination.region.slug}/${selectedDestination.subregion.slug}`)
    } else if (selectedDestination.region) {
      router.push(`/${selectedDestination.country.slug}/${selectedDestination.region.slug}`)
    }

  }, [selectedDestination])


  return (
    <PublicModal isOpen={isOpen} onClose={onClose} className="h-full md:!h-full lg:!h-min overflow-y-hidden">
      <MobileSearchbarContent
        isHomepage={true}
        closeable={true}
        selectedDestination={selectedDestination}
        setSelectedDestination={setSelectedDestination}
        onClose={onClose}
      />
    </PublicModal>
  )
}

MobileDestinationsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default MobileDestinationsModal
