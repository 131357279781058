import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { FieldError } from 'react-hook-form'
import Label from 'components/form/Home/Label'
import Error from 'components/form/Error'

interface TextareaPropTypes extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'dangerouslySetInnerHTML'|'children'|'className'> {
  className?: {
    container?: string
    label?: string
    wrapper?: string
    textarea?: string
    error?: string
  }
  label?: string
  capitalise?: boolean
  error?: FieldError
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaPropTypes>(function Textarea ({
  className,
  label,
  capitalise = false,
  error,
  ...props
}, ref) {

  return (
    <div className={classNames('flex flex-col w-full', className?.container)}>
      {
        label && (
          <Label>{label}</Label>
        )
      }

      <div className={classNames(
        'w-full text-16 ring-2 focus:ring-opacity-50 rounded-16 flex items-center',
        {
          'ring-red ring-opacity-50': error,
          'ring-grey-100': !error,
        },
        className?.wrapper,
      )}>
        <textarea
          ref={ref}
          rows={5}
          className={classNames(
            'text-grey-800 placeholder-grey-650 text-14 leading-24 w-full bg-grey-100 px-20 py-15 rounded-16',
            'border-none focus:outline-none !ring-2 focus:ring-grey-150',
            {
              'capitalize': capitalise,
            },
            {
              'ring-red ring-opacity-50': error,
              'ring-grey-100': !error,
            },
            className?.textarea,
          )}
          {...props}
        />
      </div>

      <Error className="mt-10" error={error?.message} />
    </div>
  )
})


export default Textarea
